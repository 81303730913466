<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="tim-icons icon-gift-2"/>
        <sidebar-link to="/nfts" :name="$t('sidebar.nfts')" icon="tim-icons icon-zoom-split"/>
        <sidebar-link to="/staking" :name="'Staking'" icon="tim-icons icon-coins"/>
        <sidebar-link to="/bounties" :name="'Bounties'" icon="tim-icons icon-trophy"/>
        <sidebar-link to="/managenfts" :name="'Manage NFTs'" icon="tim-icons icon-send"/>
        <sidebar-link to="/tradenfts" :name="'Trade NFTs'" icon="tim-icons icon-money-coins"/>
        <sidebar-link to="/checklists" :name=" 'How rare is your card?'" icon="tim-icons icon-check-2"/>
        <sidebar-link to="/information" :name="'Help / Videos / Contracts'" icon="tim-icons icon-compass-05"/>
        
        <!-- <sidebar-link to="/testsandbox" :name="'test'" icon="tim-icons icon-puzzle-10"/> -->
        <!-- <sidebar-link to="/icons" :name="$t('sidebar.icons')" icon="tim-icons icon-atom"/> -->
        <!-- <sidebar-link to="/maps" :name="$t('sidebar.maps')" icon="tim-icons icon-pin"/> -->
        <!-- <sidebar-link to="/notifications" :name="$t('sidebar.notifications')" icon="tim-icons icon-bell-55"/> -->
        <!-- <sidebar-link to="/profile" :name="$t('sidebar.userProfile')" icon="tim-icons icon-single-02"/> -->
        <!-- <sidebar-link to="/table-list" :name="$t('sidebar.tableList')" icon="tim-icons icon-puzzle-10"/> -->
        <!-- <sidebar-link to="/typography" :name="$t('sidebar.typography')" icon="tim-icons icon-align-center"/> -->
        <!-- <sidebar-link to="/dashboard?enableRTL=true" :name="$t('sidebar.rtlSupport')" icon="tim-icons icon-world"/> -->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">
      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
