/*
 =========================================================
  Branch Continued OCTOBER 8 2021
 */
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from 'vue-router-prefetch'
import App from "./App";
import Vuex from 'vuex';
import VueAnalytics from 'vue-analytics';
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from "./router/index";

import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n"
import './registerServiceWorker'
import ToggleButton from 'vue-js-toggle-button'
import Web3 from 'web3'
import VueKinesis from 'vue-kinesis'

Vue.use(ToggleButton);
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(Vuex);
Vue.use(VueKinesis);
Vue.use(VueAnalytics, {
  id: 'G-BXZELEJVM4'
});

// todocw potentially import these from app?
const mainnet_contracts = require("./contracts_data.json");
const testnet_contracts = require("./test_contracts_data.json");
const globals = require("./globals.json");
const abis = require("./abis.json");

let abi_block_card = abis.block_cards[0][1];
let abi_bounty = abis.bounties[0][1];
let abi_bounty_5 = abis.bounties[1][1];
let abi_bounty_t = abis.bounties[2][1];

let contract_data;

let current_read_api;

let current_block_cards_stake

if (globals.testnet) {
  // using testnet
  // console.log("using testnet");
  current_block_cards_stake = testnet_contracts.block_cards
  contract_data = testnet_contracts
  current_read_api = globals.testnet_read_api
}
else {
  // using mainnet
  // console.log("using mainnet");
  contract_data = mainnet_contracts
  current_read_api = globals.mainnet_read_api
  current_block_cards_stake = mainnet_contracts.block_cards
}

let current_block_cards = contract_data.block_cards;
let current_bounties = contract_data.bounties;

const redeem_bounty_with_web3 = async (address_of_cards, bounty_address, token_ids)=> {
  let web3_accounts
  let accounts
  let match_card
  let message = "redeemed"
  let redeem_result
  try{
    web3_accounts = new Web3(window.ethereum); 
    accounts = await web3_accounts.eth.getAccounts()
  }
  catch{
    console.log("no wallet")
  }
  let web3_1 = new Web3(window.ethereum);
  let contract_token_1 = new web3_1.eth.Contract(abi_bounty,bounty_address)
  try{
    redeem_result = await contract_token_1.methods.redeem_bounty(token_ids,address_of_cards).send({ from: accounts[0] })
  }
  catch(e){
    message = e.toString()
    console.log("hi bounty",message)

    return {"result": false, "message": message}
  }
  console.log(redeem_result)
  return {"result": true, "message": message}
}


const match_card_bounty_with_web3 = async (bounty_address, tokenId, card_index, block_card_type_address) => {
  let web3_accounts
  let accounts
  let match_card
  let message = "Matched"
  try{
    web3_accounts = new Web3(window.ethereum); 
    accounts = await web3_accounts.eth.getAccounts()
  }
  catch{
    console.log("no wallet")
  }
  let web3_1 = new Web3(current_read_api);
  let contract_token_1 = new web3_1.eth.Contract(abi_bounty,bounty_address)
  try{
    match_card = await contract_token_1.methods.match_card(
    accounts[0],tokenId,card_index,block_card_type_address).call()
  }
  catch(e){
    message = e.toString()
    console.log("hi match card",message)

    return {"result": false, "message": message}
  }
  console.log(match_card)
  return {"result": true, "message": message}
}

const bounty_info_with_web3 = async (bounty_address,bounty_num) => {
  let web3_accounts
  let accounts
  let info_about
  let message = "got info"
  try{
    web3_accounts = new Web3(window.ethereum); 
    accounts = await web3_accounts.eth.getAccounts()
  }
  catch{
    console.log("no wallet")
  }
  let web3_1 = new Web3(current_read_api);
  let current_abi = abi_bounty
  console.log("wondering", bounty_num)

  current_abi = abis.bounties[bounty_num][1];
  
  
  let contract_token_1 = new web3_1.eth.Contract(current_abi,bounty_address)
  try{
    info_about = await contract_token_1.methods.bounty_info().call()
  }
  catch(e){
    message = e.toString()
    console.log("hi bounty info",message)

    return {"result": false, "info_about": message}
  }
  console.log("info_about", info_about)
  return {"result": true, "info_about": info_about}
}

const fetch_specific_block_card_with_web3 = async (i,tokenId,account) => {
  //
  let web3_accounts
  let accounts
  try{
    web3_accounts = new Web3(window.ethereum); 
    accounts = await web3_accounts.eth.getAccounts()
  }
  catch{
    console.log("no wallet")
  }
  let web3_1 = new Web3(current_read_api);
  let contract_token_1 = new web3_1.eth.Contract(abi_block_card,current_block_cards[i].address)
  console.log(account,"THE account2")
  let current_nft
  let owners_balance
  let card_info
  let owner 
  try{
        
        // current_nft = await contract_token_1.methods.tokenOfOwnerByIndex(account,j).call({from: account})
        if (account != undefined){
          owners_balance = await contract_token_1.methods.balanceOf(account).call()
        }
        else{
          owners_balance = 0
        }
        // owners_balance = await contract_token_1.methods.balanceOf(account).call()
        card_info = await contract_token_1.methods.card_info(tokenId).call()
        owner = await contract_token_1.methods.ownerOf(tokenId).call()
        console.log("current token",current_nft)
        console.log("card info",card_info,owner)

        let nft_uri = await contract_token_1.methods.tokenURI(tokenId).call()
        console.log("token URI fetched: ", nft_uri);
        let just_hash = nft_uri.slice(7)
        just_hash = "QmS68e8vTsy3oyXgmFzvWp2f67odtq2Bv64JUnAJwoczVA"
        let link = "https://gateway.pinata.cloud/ipfs/"+ just_hash
        // let link_result = await fetchJsonGetImage(link)
        // console.log("link_result: ", link_result) // 
        let is_owned = false
        if (accounts){
          console.log(accounts, "merry")
        
        if (accounts[0] == owner){
          is_owned = true
        }
      }
        return ({"card_info":['link_result.link_image','current_block_card_images[i]',link,current_block_cards[i].name,current_block_cards[i].address,
        owners_balance,card_info,String(tokenId),i], "owner": owner, "is_owned":is_owned});
  }
  catch(err){
      console.log(err) //
      return false
  }
}


const fetch_block_card_with_web3 = async (i,j,account) => {
  //
  // let web3_1 = new Web3(window.ethereum); 
  let web3_1 = new Web3(current_read_api);
  let contract_token_1 = new web3_1.eth.Contract(abi_block_card,current_block_cards[i].address)
  console.log(account,"THE account")
  let current_nft
  let owners_balance
  let card_info 
  try{
        current_nft = await contract_token_1.methods.tokenOfOwnerByIndex(account,j).call({from: account})
        owners_balance = await contract_token_1.methods.balanceOf(account).call({from: account})
        card_info = await contract_token_1.methods.card_info(current_nft).call({from: account})
        console.log("current token",current_nft)
        console.log("card info",card_info)
  }
  catch(err){
      // console.log(err) //
      return false
  }
  if (current_nft){
    let nft_uri = await contract_token_1.methods.tokenURI(current_nft).call()
    console.log("token URI fetched: ", nft_uri);
    let just_hash = nft_uri.slice(7)
    just_hash = "QmS68e8vTsy3oyXgmFzvWp2f67odtq2Bv64JUnAJwoczVA"
    let link = "https://gateway.pinata.cloud/ipfs/"+ just_hash
    // let link_result = await fetchJsonGetImage(link)
    // console.log("link_result: ", link_result) // 
    return (['link_result.link_image','current_block_card_images[i]',link,current_block_cards[i].name,current_block_cards[i].address,
    owners_balance,card_info,current_nft,i]);
  }
  
}


const fetch_locker_nft_with_web3 = async (i,j,account) => {
  //
  // let web3_1 = new Web3(window.ethereum); // todo decide what to do here
  // const url = 'https://apis-sj.ankr.com/79bea2923d214598b14290281c2955ef/10707bea3215810fb4fd4ae012380bc3/binance/full/test'  // url string
  //  const url = 'https://elemints:elemints@apis.ankr.com/binance/full/test'  // url string

  let web3_1 = new Web3(current_read_api);

  // const provider = new Web3.providers.HttpProvider("https://bsc-dataseed1.defibit.io/");
  // let web3_1 = new Web3("https://data-seed-prebsc-1-s1.binance.org:8545/");
  let relevant_block_card_address = current_block_cards_stake[i].address

  let contract_token_1 = new web3_1.eth.Contract(abis.lockers[0][1],contract_data.lockers[0].address)
  // console.log(account,"THE account")
  let current_nft
  let owners_balance = "irrelevant"
  let card_info 
  try{
        // current_nft = await contract_token_1.methods.tokenOfOwnerByIndex(account,j).call({from: account})
        // owners_balance = await contract_token_1.methods.balanceOf(account).call({from: account})
        // card_info = await contract_token_1.methods.card_info(current_nft).call({from: account})
        current_nft = await contract_token_1.methods.tokenOfOwnerByIndex(relevant_block_card_address,account,j).call()
        // owners_balance = await contract_token_1.methods.balanceOf(account).call()
        // card_info = await contract_token_1.methods.card_info(token_id).call()
        // console.log("current token",current_nft)
        // console.log("card info",card_info)
  }
  catch(err){
      // console.log(err)
      return false
  }
  try{
    if (current_nft){
    // let nft_uri = await contract_token_1.methods.tokenURI(current_nft).call()
    // console.log("token URI fetched: ", nft_uri);
    // let just_hash = nft_uri.slice(7)
    // just_hash = "QmS68e8vTsy3oyXgmFzvWp2f67odtq2Bv64JUnAJwoczVA"
    // let link = "https://gateway.pinata.cloud/ipfs/"+ just_hash
    // let link_result = await fetchJsonGetImage(link)
    // console.log("link_result: ", link_result)
    // return (['link_result.link_image','current_block_card_images[i]',link,current_block_cards_stake[i].name,current_block_cards_stake[i].address,
    // owners_balance,card_info,current_nft,i]);
    return(current_nft)
  }

  }
  catch(err){
    console.log("uri/return failed",err)
    return false
  }
}


const fetch_nft_for_staking = async (i,token_id,account) => {
  console.log("special nums", i,token_id,account)
  //
  // let web3_1 = new Web3(window.ethereum); // todo decide what to do here
  // const url = 'https://apis-sj.ankr.com/79bea2923d214598b14290281c2955ef/10707bea3215810fb4fd4ae012380bc3/binance/full/test'  // url string
  //  const url = 'https://elemints:elemints@apis.ankr.com/binance/full/test'  // url string

  let web3_1 = new Web3(current_read_api);

  // const provider = new Web3.providers.HttpProvider("https://bsc-dataseed1.defibit.io/");
  // let web3_1 = new Web3("https://data-seed-prebsc-1-s1.binance.org:8545/");
  
  let contract_token_1 = new web3_1.eth.Contract(abi_block_card,current_block_cards_stake[i].address)
  // console.log(account,"THE account")
  let current_nft = token_id
  let owners_balance
  let card_info 
  console.log(account,token_id)
  try{
        // current_nft = await contract_token_1.methods.tokenOfOwnerByIndex(account,j).call({from: account})
        // owners_balance = await contract_token_1.methods.balanceOf(account).call({from: account})
        // card_info = await contract_token_1.methods.card_info(current_nft).call({from: account})
        // current_nft = await contract_token_1.methods.tokenOfOwnerByIndex(account,j).call()
        owners_balance = await contract_token_1.methods.balanceOf(account).call()
        card_info = await contract_token_1.methods.card_info(token_id).call()
        // console.log("current token",current_nft)
        // console.log("card info",card_info)
  }
  catch(err){
      console.log(err)
      return false
  }
 

    

    // console.log("dusty3",dust)
  try{
    if (current_nft){
    let nft_uri = await contract_token_1.methods.tokenURI(current_nft).call()

    let abi_locker = abis.lockers[0][1];
    
  

  let web3_a = new Web3(current_read_api);
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()

  let staker_address = contract_data.lockers[0].address

  let locker_contract = new web3_a.eth.Contract(abi_locker, staker_address)
  
  let dust = await locker_contract.methods.get_dust_to_unlock(accounts[0],current_block_cards_stake[i].address,
  token_id).call({from: accounts[0]} )
  dust = dust / 1e18
   card_info["dust"] = dust.toFixed(0) + 2
    // console.log("token URI fetched: ", nft_uri);
    let just_hash = nft_uri.slice(7)
    // just_hash = "QmS68e8vTsy3oyXgmFzvWp2f67odtq2Bv64JUnAJwoczVA"
    let link = "https://gateway.pinata.cloud/ipfs/"+ just_hash
    // let link_result = await fetchJsonGetImage(link)
    // console.log("link_result: ", link_result)
    return (['link_result.link_image','current_block_card_images[i]',link,current_block_cards_stake[i].name,current_block_cards_stake[i].address,
    owners_balance,card_info,current_nft,i]);
  }

  }
  catch(err){
    console.log("uri/return failed",err)
    return false
  }

}


const approve_erc20_with_web3 = async (erc20_address, address_to_approve) => {
  console.log("calling approve_erc20_with_web3")
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()
  let erc20_contract = new web3_1.eth.Contract(abis.erc20, erc20_address)
  console.log("approving", address_to_approve)
  let result = await erc20_contract.methods.approve(address_to_approve, 1000000000000000000000000000000000000000000000n).send({ from: accounts[0] })
  console.log("attempt to approve:", result)
}

const approve_dust_with_web3 = async (erc20_address, address_to_approve) => {
  console.log("calling approve_erc20_with_web3")
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()
  let erc20_contract = new web3_1.eth.Contract(abis.erc20, erc20_address)
  console.log("approving", address_to_approve)
  let result = await erc20_contract.methods.approve(address_to_approve, 1000000000000000000000000000000000000000000000n).send({ from: accounts[0] })
  console.log("attempt to approve:", result)
  return result
}


// const mint_nft_with_web3 = async (minter_info) =>{
//   console.log("calling mint_nft_with_web3")
//   let web3_1 = new Web3(window.ethereum);
//   let accounts = await web3_1.eth.getAccounts()
//   // below // because of indexing at 1 needs - 1
//   let minter_contract = new web3_1.eth.Contract(abis.minters[minter_info.id-1][1],contract_data.minters[minter_info.id-1].address)
//   console.log("minting",minter_info.id,abis.minters[0][1])
//   let result = await minter_contract.methods.mint().send({from: accounts[0]})
//   console.log("attempt to mint:", result)
// }
const mint_nft_with_web3 = async (minter_info,address,number) => {
  console.log("calling mint_nft_with_web3", minter_info,address,number)
  // return 
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()
  // below // because of indexing at 1 needs - 1
  let minter_contract = new web3_1.eth.Contract(abis.block_pack_minters[minter_info.id - 1][1], contract_data.block_pack_minters[minter_info.id - 1].address)
  let price_result = await minter_contract.methods.getNativePrice().call({ from: accounts[0] })
  let price_to_send = Number(price_result) + 1e15
  let result
  if (contract_data.block_pack_minters[minter_info.id - 1].mint_multiple == false ){
    result = await minter_contract.methods.mint_with_nativecoin().send({ from: accounts[0], value: price_to_send })
  }
  else if (address == undefined){
    if (number == undefined){
      result = await minter_contract.methods.mint_multiple_with_nativecoin(1,accounts[0]).send({ from: accounts[0], value: price_to_send })
    }
    else{
      price_to_send = price_to_send * number
      result = await minter_contract.methods.mint_multiple_with_nativecoin(number,accounts[0]).send({ from: accounts[0], value: price_to_send })
    }
  }
  else{
    if (number == undefined){
      result = await minter_contract.methods.mint_multiple_with_nativecoin(1,address).send({ from: accounts[0], value: price_to_send })
    }
    else{
      price_to_send = price_to_send * number
      result = await minter_contract.methods.mint_multiple_with_nativecoin(number,address).send({ from: accounts[0], value: price_to_send })
    } 
  }
  // let result = await minter_contract.methods.mint_with_nativecoin().send({ from: accounts[0], value: price_to_send })
  console.log("attempt to mint:", result)
  return result
}

const mint_single_nft_with_web3 = async (minter_info,address,number) => {
  console.log("calling mint_single_nft_with_web3", minter_info,address,number)
  // return 
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()
  // below // because of indexing at 1 needs - 1
  let minter_contract = new web3_1.eth.Contract(abis.single_minters[minter_info.id - 1][1], contract_data.single_minters[minter_info.id - 1].address)
  let price_result = await minter_contract.methods.getNativePrice().call({ from: accounts[0] })
  let price_to_send = Number(price_result) + 1e15
  let result
  if (contract_data.single_minters[minter_info.id - 1].mint_multiple == false ){
    result = await minter_contract.methods.mint_with_nativecoin().send({ from: accounts[0], value: price_to_send })
  }
  else if (address == undefined){
    if (number == undefined){
      result = await minter_contract.methods.mint_multiple_with_nativecoin(1,accounts[0]).send({ from: accounts[0], value: price_to_send })
    }
    else{
      price_to_send = price_to_send * number
      result = await minter_contract.methods.mint_multiple_with_nativecoin(number,accounts[0]).send({ from: accounts[0], value: price_to_send })
    }
  }
  else{
    if (number == undefined){
      result = await minter_contract.methods.mint_multiple_with_nativecoin(1,address).send({ from: accounts[0], value: price_to_send })
    }
    else{
      price_to_send = price_to_send * number
      result = await minter_contract.methods.mint_multiple_with_nativecoin(number,address).send({ from: accounts[0], value: price_to_send })
    } 
  }
  // let result = await minter_contract.methods.mint_with_nativecoin().send({ from: accounts[0], value: price_to_send })
  console.log("attempt to mint:", result)
  return result
}



const open_block_pack_with_web3 = async (index) => {
  console.log("calling open_block_pack_with_web3 at index: ", index)
  // return
  let abi_block_pack = abis.block_packs[index][1];
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()
  // below // because of indexing at 1 needs - 1
  let blockpack_contract = new web3_1.eth.Contract(abi_block_pack, contract_data.block_packs[index].address)
  // let price_result = await blockpack_contract.methods.getNativePrice().call({from: accounts[0]})
  let first_nft = await blockpack_contract.methods.tokenOfOwnerByIndex(accounts[0], 0).call({ from: accounts[0] })

  console.log("first nft: ", first_nft)
  // let result = await blockpack_contract.methods.openAndBurn(first_nft).send({ from: accounts[0]})
  let result = await blockpack_contract.methods.openAndBurn(first_nft).send({ from: accounts[0], gas:1850000 })
  return result
}

const transfer_block_pack_with_web3 = async (index,to_address,token_num) => {
  console.log("calling transfer_block_pack_with_web3 at index: ", index)
  // return
  let abi_block_pack = abis.block_packs[index][1];
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()

  let blockpack_contract = new web3_1.eth.Contract(abi_block_pack, contract_data.block_packs[index].address)

  let result = await blockpack_contract.methods.transferFrom(accounts[0], to_address,token_num).send({ from: accounts[0] })
  console.log(result)
  return result
}

const send_block_card_with_web3 = async (to_address, tokenId, index) => {
  console.log("calling send_block_card_with_web3 at index: ", index)
  // return
  let abi_block_cards = abis.block_cards[index][1];
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()

  let block_card_contract = new web3_1.eth.Contract(abi_block_cards, contract_data.block_cards[index].address)

  let result = await block_card_contract.methods.transferFrom(accounts[0], to_address,tokenId).send({ from: accounts[0] })
  console.log(result)
  return result
}

const list_block_card_with_web3 = async (tokenId, index, value_amount, trade_reciever) => {
  console.log("calling list_block_card_with_web3 at index: ", index)
  // return
  let abi_trader = abis.traders[0][1];
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()

  let trader_contract = new web3_1.eth.Contract(abi_trader, contract_data.traders[0].address)

  let result = await trader_contract.methods.setTrade(contract_data.block_cards[index].address,tokenId,
  value_amount,trade_reciever).send({ from: accounts[0] })
  console.log(result)
  return result
}

const stake_block_card_with_web3 = async (tokenId, index) => {
  console.log("calling stake_block_card_with_web3 at index: ", index)
  // return
  let abi_staker = abis.lockers[0][1];
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()

  let staker_contract = new web3_1.eth.Contract(abi_staker, contract_data.lockers[0].address)

  let result = await staker_contract.methods.lockCard(contract_data.block_cards[index].address,tokenId
  ).send({ from: accounts[0] })
  console.log(result)
  return result
}

const get_dust_to_unlock_with_web3 = async (tokenId, address) => {
  console.log("calling get_dust_to_unlock_with_web3 at index: ", index)
 
 let abi_locker = abis.lockers[0][1];
  
 let block_card_address = contract_data.block_cards[index].address

 let web3_a = new Web3(current_read_api);
 let web3_1 = new Web3(window.ethereum);
 let accounts = await web3_1.eth.getAccounts()

 let staker_address = contract_data.lockers[0].address
 
 let locker_contract = new web3_a.eth.Contract(abi_locker, staker_address)
 console.log("dusty2:", block_card_address,token_id)
 let result = await locker_contract.methods.get_dust_to_unlock(address,tokenId).call({from: accounts[0]} )

  console.log(result)

  
  return result
}




const trade_block_card_with_web3 = async (tokenId, index, value_amount, trade_reciever) => {
  console.log("calling trade_block_card_with_web3 at index: ", index)
  // return
  let abi_trader = abis.traders[0][1];
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()

  let trader_contract = new web3_1.eth.Contract(abi_trader, contract_data.traders[0].address)

  let result = await trader_contract.methods.executeTrade(contract_data.block_cards[index].address,tokenId,
  accounts[0]).send({ from: accounts[0], value: value_amount })
  console.log(result)
  return result
}

const trade_info_with_web3 = async (tokenId, index) => {
    console.log("calling trade_info_with_web3 at index: ", index)
    // return
    let abi_block_cards = abis.block_cards[index][1];
    let web3_a = new Web3(current_read_api);
    let web3_1 = new Web3(window.ethereum);
    let accounts = await web3_1.eth.getAccounts()

    let block_card_contract = new web3_a.eth.Contract(abi_block_cards, contract_data.block_cards[index].address)
    let trader_address = contract_data.traders[0].address
  
    
    let abi_trader = abis.traders[0][1];

    let trader_contract = new web3_a.eth.Contract(abi_trader, contract_data.traders[0].address)

    let trade_result_listed = await trader_contract.methods.nft_for_sale_or_not(contract_data.block_cards[index].address,tokenId).call()

    let trade_result_price = await trader_contract.methods.nft_prices(contract_data.block_cards[index].address,tokenId).call()

    let target_address = await trader_contract.methods.nft_buyers(contract_data.block_cards[index].address,tokenId).call()

    let payee_address = await trader_contract.methods.nft_payees(contract_data.block_cards[index].address,tokenId).call()

    let approval_result = await block_card_contract.methods.isApprovedForAll(payee_address, trader_address).call()
    console.log("approved looks like", approval_result)

    let account_eligible = false

    if (target_address == "0x0000000000000000000000000000000000000000" || target_address == accounts[0]){
      account_eligible = true
    }

    console.log("trade_result_listed",trade_result_listed, trade_result_price)
    
    return {"approval_result": approval_result , 
      "trade_result_listed": trade_result_listed,
      "trade_result_price": trade_result_price,
      "account_eligible": account_eligible }
}


const is_approved_for_with_web3 = async (index) => {
  console.log("calling is_approved_for_with_web3 at index: ", index)
  // return
  let abi_block_cards = abis.block_cards[index][1];
  let web3_a = new Web3(current_read_api);
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()

  let block_card_contract = new web3_a.eth.Contract(abi_block_cards, contract_data.block_cards[index].address)
  let trader_address = contract_data.traders[0].address
  let result = await block_card_contract.methods.isApprovedForAll(accounts[0], trader_address).call()
  console.log(result)
  return result
}

const is_approved_for_staking_with_web3 = async (index) => {
  console.log("calling is_approved_for_staking_with_web3 at index: ", index)
  // return
  let abi_block_cards = abis.block_cards[index][1];
  let web3_a = new Web3(current_read_api);
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()

  let block_card_contract = new web3_a.eth.Contract(abi_block_cards, contract_data.block_cards[index].address)
  let staker_address = contract_data.lockers[0].address
  let result = await block_card_contract.methods.isApprovedForAll(accounts[0], staker_address).call()
  console.log(result)
  return result
}

const calculate_dust_with_web3 = async  (index,token_id) => {
  
  
  let abi_locker = abis.lockers[0][1];
  
  let block_card_address = contract_data.block_cards[index].address

  let web3_a = new Web3(current_read_api);
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()

  let staker_address = contract_data.lockers[0].address
  
  let locker_contract = new web3_a.eth.Contract(abi_locker, staker_address)
  console.log("dusty:", block_card_address,token_id)
  let result = await locker_contract.methods.predict_dust(block_card_address,token_id).call({from: accounts[0]} )
  // let price = await info_contract.methods.getOracle(accounts[0], staker_address).call()

  console.log(result)
  result = result / (1e18)
  return result
}



const get_dust_staked_with_web3 = async  (address) => {
  
  let abi_staking = abis.staking[0][1];

  // console.log("what iss", abi_staking)

  // 

  let web3_a = new Web3(current_read_api);
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()

  let staker_address = contract_data.stakers[0].address
  
  let staker_contract = new web3_a.eth.Contract(abi_staking, staker_address)

  let result = await staker_contract.methods.balanceOf(accounts[0]).call({from: accounts[0]} )
  // let price = await info_contract.methods.getOracle(accounts[0], staker_address).call()

  // console.log(result)
  // result = result / (1e18)
  return result
}


const dust_stake_with_web3 = async  (amount) => {
  
  let abi_staking = abis.staking[0][1];

  // console.log("what iss", abi_staking)

  // 

  let web3_a = new Web3(current_read_api);
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()

  let staker_address = contract_data.stakers[0].address
  
  let staker_contract = new web3_1.eth.Contract(abi_staking, staker_address)
  console.log(amount,"amount")

  amount = parseInt(amount)
  amount = BigInt(amount) * BigInt(10**18)
  // amount = toString(amount)

  console.log(amount,"amount again")

  
  let result = await staker_contract.methods.stake(amount).send({from: accounts[0]} )
  // let price = await info_contract.methods.getOracle(accounts[0], staker_address).call()

  // console.log(result)
  // result = result / (1e18)
  return result
}

const unstake_dust_with_web3 = async  (amount) => {
  
  let abi_staking = abis.staking[0][1];

  // console.log("what iss", abi_staking)

  // 

  let web3_a = new Web3(current_read_api);
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()

  let staker_address = contract_data.stakers[0].address
  
  let staker_contract = new web3_1.eth.Contract(abi_staking, staker_address)
  console.log(amount,"amount")

  amount = parseInt(amount)
  amount = BigInt(amount) * BigInt(10**18)
  // amount = toString(amount)

  console.log(amount,"amount again")

  
  let result = await staker_contract.methods.withdraw(amount).send({from: accounts[0]} )
  // let price = await info_contract.methods.getOracle(accounts[0], staker_address).call()

  // console.log(result)
  // result = result / (1e18)
  return result
}



const redeem_rewards_with_web3 = async  () => {
  
  let abi_staking = abis.staking[0][1];

  // console.log("what iss", abi_staking)

  // 
  let web3_a = new Web3(current_read_api);
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()

  let staker_address = contract_data.stakers[0].address
  
  let staker_contract = new web3_1.eth.Contract(abi_staking, staker_address)

  let result = await staker_contract.methods.getReward().send({from: accounts[0]} )
  // let price = await info_contract.methods.getOracle(accounts[0], staker_address).call()

  // console.log(result)
  // result = result / (1e18)
  return result
}

const get_rewards_earned_with_web3 = async  () => {
  
  let abi_staking = abis.staking[0][1];

  // console.log("what iss", abi_staking)

  // 
  let web3_a = new Web3(current_read_api);
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()

  let staker_address = contract_data.stakers[0].address
  
  let staker_contract = new web3_a.eth.Contract(abi_staking, staker_address)

  let result = await staker_contract.methods.earned(accounts[0]).call({from: accounts[0]} )
  // let price = await info_contract.methods.getOracle(accounts[0], staker_address).call()

  // console.log(result)
  // result = result / (1e18)
  return result
}


const approve_nft_with_web3 = async (index,approve_type) => {
  console.log("calling approve_nft_with_web3 at index: ", index)
  // return
  let abi_block_cards = abis.block_cards[index][1];
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()

  let block_card_contract = new web3_1.eth.Contract(abi_block_cards, contract_data.block_cards[index].address)
  let approval_address
  let trader_address = contract_data.traders[0].address
  approval_address = trader_address
  if (approve_type == "locker"){
    approval_address = contract_data.lockers[0].address
  }
  let result = await block_card_contract.methods.setApprovalForAll(approval_address, true).send({ from: accounts[0] })
  console.log(result)
  return result
}

const redeem_block_card_with_web3 = async (base_id, card_id, card_address) => {
  console.log("calling redeem_block_card_with_web3 :base_id, card_id, address: ", base_id, card_id, card_address, contract_data.block_cards[base_id].address)
  // return
  let abi_block_card = abis.block_cards[base_id][1];
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()
  // below // because of indexing at 1 needs - 1
  let blockcard_contract = new web3_1.eth.Contract(abi_block_card, contract_data.block_cards[base_id].address)
  // let price_result = await blockpack_contract.methods.getNativePrice().call({from: accounts[0]})
  // let first_nft = await blockcard_contract.methods.tokenOfOwnerByIndex(accounts[0],0).call({from: accounts[0]})

  // console.log("first nft: ", first_nft)
  let result = await blockcard_contract.methods.burnAndRedeem(card_id).send({ from: accounts[0] })
  return result
}

const unstake_block_card_with_web3 = async (base_id, card_id, card_address) => {
  console.log("calling unstake_block_card_with_web3 :base_id, card_id, address: ", base_id, card_id, card_address, contract_data.block_cards[base_id].address)
  // return
  let abi_locker = abis.lockers[0][1];
  // let abi_block_card = abis.block_cards[base_id][1];
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()
  // below // because of indexing at 1 needs - 1
  // let blockcard_contract = new web3_1.eth.Contract(abi_locker, contract_data.block_cards[base_id].address)
  let locker_contract = new web3_1.eth.Contract(abi_locker, contract_data.lockers[0].address)

  let result = await locker_contract.methods.unlockCard(contract_data.block_cards[base_id].address, card_id).send({ from: accounts[0] })

  return result
}

const check_approved_with_web3 = async (erc20_address, address_to_check_allowance) => {
  console.log("calling check_approved_with_web3")
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()
  let erc20_contract = new web3_1.eth.Contract(abis.erc20, erc20_address)
  let result = await erc20_contract.methods.allowance(accounts[0], address_to_check_allowance).call({ from: accounts[0] })
  console.log("attempt to check allowance:", result)
  if (result > 1000000000000000000000000000000000000000n) {
    return false
  }
  else {
    return true
  }
}


const get_coin_balance_with_web3 = async (erc20_address) => {
  console.log("calling get_coin_balance_with_web3")
  let web3_1 = new Web3(window.ethereum);
  let web3_a = new Web3(current_read_api);
  let accounts = await web3_1.eth.getAccounts()
  let erc20_contract = new web3_a.eth.Contract(abis.erc20, erc20_address)
  let result = await erc20_contract.methods.balanceOf(accounts[0]).call({ from: accounts[0] })
  return result
}


const check_dust_approved_with_web3 = async () => {
  console.log("calling check_dust_approved_with_web3")
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()
  let web3_a = new Web3(current_read_api);
  let erc20_contract = new web3_a.eth.Contract(abis.erc20, contract_data.dust)
  // check locker
  let result = await erc20_contract.methods.allowance(accounts[0], contract_data.lockers[0].address).call({ from: accounts[0] })
  console.log("attempt to check allowance:", result)
  if (result > 1000000000000000000000000000000000000000n) {
    return true
  }
  else {
    return false
  }
}

const check_dust_approved_with_web3_general = async (address) => {
  console.log("calling check_dust_approved_with_web3_general")
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()
  let web3_a = new Web3(current_read_api);
  let erc20_contract = new web3_a.eth.Contract(abis.erc20, contract_data.dust)
  
  // check locker
  let result = await erc20_contract.methods.allowance(accounts[0], address).call({ from: accounts[0] })
  console.log("attempt to check allowance for staker:", result)
  if (result > 1000000000000000000000000000000000000000n) {
    return true
  }
  else {
    return false
  }
}





const get_elemint_tokenomics_with_web3 = async () => {
  console.log("calling get_elemint_tokenomics_with_web3")
  let web3_1 = new Web3(window.ethereum)
  let accounts = await web3_1.eth.getAccounts()
  let elemint_contract = new web3_1.eth.Contract(abis.abi_elemint, contract_data.elemint_token.address)
  let result = await elemint_contract.methods.totalSupply().call({ from: accounts[0] })
  console.log("result of calling totalSupply", result)
  return result

}

const get_price_with_web3 = async (nft_id) => {
  console.log("calling get price web3")
  // let web3_1 = new Web3(window.ethereum);
  let web3_a = new Web3(current_read_api);
  // let accounts = await web3_1.eth.getAccounts()
  // let minter_contract = new web3_1.eth.Contract(abis.block_pack_minters[nft_id - 1][1], contract_data.block_pack_minters[nft_id - 1].address)
  // let result = await minter_contract.methods.getNativePrice().call({ from: accounts[0] })
  let minter_contract = new web3_a.eth.Contract(abis.block_pack_minters[nft_id - 1][1], contract_data.block_pack_minters[nft_id - 1].address)
  let result = await minter_contract.methods.getNativePrice().call()
  console.log("attempt to price:", result)
  return result
}


const get_single_price_with_web3 = async (nft_id) => {
  console.log("calling get price web3")
  // let web3_1 = new Web3(window.ethereum);
  let web3_a = new Web3(current_read_api);
  // let accounts = await web3_1.eth.getAccounts()
  // let minter_contract = new web3_1.eth.Contract(abis.block_pack_minters[nft_id - 1][1], contract_data.block_pack_minters[nft_id - 1].address)
  // let result = await minter_contract.methods.getNativePrice().call({ from: accounts[0] })
  let minter_contract = new web3_a.eth.Contract(abis.single_minters[nft_id - 1][1], contract_data.single_minters[nft_id - 1].address)
  let result = await minter_contract.methods.getNativePrice().call()
  console.log("attempt to price:", result)
  return result
}

const get_left_with_web3 = async (nft_id) => {
  console.log("calling get left web3")
  // let web3_1 = new Web3(window.ethereum);
  let web3_a = new Web3(current_read_api);
  // let accounts = await web3_1.eth.getAccounts()
  // let minter_contract = new web3_1.eth.Contract(abis.block_pack_minters[nft_id - 1][1], contract_data.block_pack_minters[nft_id - 1].address)
  let minter_contract = new web3_a.eth.Contract(abis.block_pack_minters[nft_id - 1][1], contract_data.block_pack_minters[nft_id - 1].address)
  // let result = await minter_contract.methods.getAmmountMinted().call({ from: accounts[0] })
  let result = await minter_contract.methods.getAmmountMinted().call()
  console.log("attempt to get left:", result)
  return result
}

const add_chain_bsc_testnet_with_web3 = async () => {
  console.log("calling add_chain_bsc_testnet_with_web3")
  // try {
  //   await window.ethereum.request({
  //     method: 'wallet_switchEthereumChain',
  //     params: [{ chainId: '0x61' }],
  //   });
  // } catch (switchError) {
  //   // This error code indicates that the chain has not been added to MetaMask.
  //   if (switchError.code === 4902) {
  let result
  try {
    result = await ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: '0x61', rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'], chainName: "Testnet Binance Smart Chain",
        nativeCurrency: {
          name: "test binance coin",
          symbol: "TBNB",
          decimals: 18
        },
        blockExplorerUrls: ["https://testnet.bscscan.com/"]
      }],
    })
    console.log('getting here 22', result)
  } catch (addError) {
    // handle "add" error
    console.log(addError)
  }
  // }
  // handle other "switch" errors
  // }
  console.log('getting here343', result)
}

const add_chain_bsc_mainnet_with_web3 = async () => {
  console.log("calling add_chain_bsc_mainnet_with_web3")
  // try {
  //   await window.ethereum.request({
  //     method: 'wallet_switchEthereumChain',
  //     params: [{ chainId: '0x61' }],
  //   });
  // } catch (switchError) {
  //   // This error code indicates that the chain has not been added to MetaMask.
  //   if (switchError.code === 4902) {
  let result
  try {
    result = await ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: '0x38', rpcUrls: ['https://bsc-dataseed.binance.org/'], chainName: "Binance Smart Chain",
        nativeCurrency: {
          name: "binance coin",
          symbol: "BNB",
          decimals: 18
        },
        blockExplorerUrls: ["https://bscscan.com/"]
      }],
    })
    console.log('getting here 22', result)
  } catch (addError) {
    // handle "add" error
    console.log(addError)
  }
  // }
  // handle other "switch" errors
  // }
  console.log('getting here343', result)
}



const get_block_pack_index_with_web3 = async (base_id,num) => {
  console.log("calling get_block_pack_index_with_web3 :base_id ", base_id)
  // return
  let abi_block_pack = abis.block_packs[base_id][1];
  let web3_1 = new Web3(window.ethereum);
  let accounts = await web3_1.eth.getAccounts()
  console.log("GOT HERE")
  let blockcard_contract = new web3_1.eth.Contract(abi_block_pack, contract_data.block_packs[base_id].address)
  let result = await blockcard_contract.methods.tokenOfOwnerByIndex(accounts[0],num).call({ from: accounts[0] })
  
  return result
}


const get_total_block_card_supply_with_web3 = async (base_id) => {
  console.log("calling get_total_block_card_supply_with_web3 :base_id ", base_id)
  // return

  let abi_block_card = abis.block_cards[base_id][1];
  let web3_1 = new Web3(window.ethereum);
  let web3_a = new Web3(current_read_api);
  
  let accounts = await web3_1.eth.getAccounts()
  // let blockcard_contract = new web3_1.eth.Contract(abi_block_card, contract_data.block_cards[base_id].address)
  let blockcard_contract = new web3_a.eth.Contract(abi_block_card, contract_data.block_cards[base_id].address)
 
  let result = await blockcard_contract.methods.balanceOf(accounts[0]).call({ from: accounts[0] })

  return result
}

const get_total_locker_supply_with_web3 = async (base_id) => {
  console.log("calling get_total_locker_supply_with_web3 :base_id ", base_id)
  // return

  let abi_locker = abis.lockers[0][1];
  let web3_1 = new Web3(window.ethereum);
  let web3_a = new Web3(current_read_api);
  
  let accounts = await web3_1.eth.getAccounts()

  let locker_contract = new web3_a.eth.Contract(abi_locker, contract_data.lockers[0].address)
 
  let result = await locker_contract.methods.balanceOf(contract_data.block_cards[base_id].address, accounts[0]).call({ from: accounts[0] })

  return result

}


const get_total_block_pack_supply_with_web3 = async (base_id) => {
  console.log("calling get_total_block_card_supply_with_web3 :base_id ", base_id)
  // return
  let abi_block_pack = abis.block_packs[base_id][1];
  let web3_1 = new Web3(window.ethereum);
  let web3_a = new Web3(current_read_api);

  let accounts = await web3_1.eth.getAccounts()
  // let blockcard_contract = new web3_1.eth.Contract(abi_block_pack, contract_data.block_packs[base_id].address)
  let blockcard_contract = new web3_a.eth.Contract(abi_block_pack, contract_data.block_packs[base_id].address)
  
  let result = await blockcard_contract.methods.balanceOf(accounts[0]).call({ from: accounts[0] })
  return result
}

// const send_block_pack_with_web3 = async (base_id) => {
//   console.log("calling get_total_block_card_supply_with_web3 :base_id ", base_id)
//   // return
//   let abi_block_pack = abis.block_packs[base_id][1];
//   let web3_1 = new Web3(window.ethereum);
//   let accounts = await web3_1.eth.getAccounts()
//   let blockcard_contract = new web3_1.eth.Contract(abi_block_pack, contract_data.block_packs[base_id].address)

//   let result = await blockcard_contract.methods.balanceOf(accounts[0]).call({ from: accounts[0] })
//   return result
// }

const test_function = async (info) => {
  console.log("HELLOOO WORLD FROM STATE FUNCTION")
  console.log(info)
  return "hi"
}

export const store = new Vuex.Store({
  state: {
    block_pack_amount_hook: [],
    rerender: 0,
    account: "",
    count: 0,
    wallet_address: "Not Connected",
    elemintTokenBalance: 0,
    nfts: [],
    block_packs: [],
    block_cards: [],
    block_cards_updated: [],
    block_cards_stake_updated: [],
    bounties_updated: [],
    current_block_card_supply: localStorage.current_block_card_supply,
    current_block_card: {},
    current_block_card_info: {},
    current_block_card_base: -1,
    current_block_card_address: "",
    current_block_card_indexes: [],
    current_block_pack: {},
    current_block_pack_index: -1,
    current_block_pack_inverse_index: -1,
    current_contracts_data: contract_data,
    block_card_r_b_modal: false,
    block_pack_open_modal: false,
    modal_connect: false,
    // nfts:{nft1:"https://miro.medium.com/max/1400/1*OohqW5DGh9CQS4hLY5FXzA.png"}
    approved: [[false]],
    test_function: test_function,
    mint_spin: false,
    open_spin: false,
    redeem_spin: false,
    nav_bar_hook: 0,
    wallet_connected: false,
    // modals for blockcards
    block_card_r_b_modal0: false,
    block_card_r_b_modal1: false,
    block_card_r_b_modal2: false,
    block_card_r_b_modal3: false,
    block_card_r_b_modal4: false,

  },
  mutations: {
    increment(state) {
      state.count++
    },
    change_modal(state, payload) {
      console.log(payload.new_value)
      let modal_name = payload["modal"]
      console.log(modal_name)
      console.log(typeof modal_name)
      state[modal_name] = payload.new_value
    },
    change_state(state, payload) {
      let name = payload.name
      state[name] = payload.new_value
    },
    update_block_cards(state,payload){
      state.block_cards_updated[payload.index] = payload.card_array
    },
    stake_update_block_cards(state,payload){
      state.block_cards_stake_updated[payload.index] = payload.card_array
    }
  }
  ,
  actions: {
    async test_action(context, info) {
      console.log("HELLOOO WORLD FROM STATE action", contract_data)
      console.log(context, info)
      // context.state.approved[0] = false
      return await test_function(info)
    },
    async approve_erc20(context, payload) {
      console.log("Approving", contract_data.erc20[payload.coin_id].address, payload.address_to_approve)
      approve_erc20_with_web3(contract_data.erc20[payload.coin_id].address, payload.address_to_approve)
    },

    async approve_dust(context, payload) {
      console.log("Approving dust")
      let result = false
      try{
        result = await approve_dust_with_web3(contract_data.dust, contract_data.lockers[0].address)
      }
      catch(err){
        console.log(err)
        return result
      }
      console.log("got approval?",result)
      return result 
    },

    async approve_dust_general(context, payload) {
      console.log("Approving dust for general")
      let result = false
      try{
        result = await approve_dust_with_web3(contract_data.dust, payload.address)
      }
      catch(err){
        console.log(err)
        return result
      }
      console.log("got approval?",result)
      return result 
    },

    // async approve_dust_general(context, payload) {
    //   console.log("Approving dust")
    //   let result = false
    //   try{
    //     result = await approve_dust_with_web3(contract_data.dust, payload.approve_address)
    //   }
    //   catch(err){
    //     console.log(err)
    //     return result
    //   }
    //   console.log("got approval?",result)
    //   return result 
    // },
    
    async mint_nft(context, payload) {
      console.log("Minting NFT", payload)
      return mint_nft_with_web3(payload.nft_info,payload.address,payload.number)
    },
    async mint_single_nft(context, payload) {
      console.log("Minting SINGLE NFT", payload)
      return mint_single_nft_with_web3(payload.nft_info,payload.address,payload.number)
    },
    async check_approved(context, payload) {
      console.log("Checking Approval NFT", payload)
      let erc20_address = contract_data.erc20[payload.coin_id].address;
      console.log("CHECKING: ", payload)
      let token_id = payload.token_data;
      console.log("here", token_id, contract_data.minters)
      let address_to_check_allowance
      try {
        address_to_check_allowance = contract_data.minters[token_id - 1].address
      }
      catch (error) {
        let err = new Error("minter to approve does not exist")
        console.log(err)
        return
      }
      // let address_to_check_allowance = contract_data.minters[token_id - 1].address
      this.state.approved[0][0] = await check_approved_with_web3(erc20_address, address_to_check_allowance)
      return this.state.approved[0][0]
    },
    async check_dust_approved(){
      console.log("checking dust approval")
      return await check_dust_approved_with_web3()

    },

    async check_dust_approved_general(context,payload){
      console.log("checking dust approval general")
      return await check_dust_approved_with_web3_general(payload.address)

    },

    async get_dust_to_unlock(context,payload){
      console.log("get_dust_to_unlock")
      return await get_dust_to_unlock_with_web3(payload.bc_address,payload.token_id)

    },
    

    async get_elemint_tokenomics(context, payload) {
      // console.log("CONTEXT", context)
      let total_supply = await get_elemint_tokenomics_with_web3()
      return total_supply
    },
    async get_price(context, payload) {
      // console.log("CONTEXT", context)
      console.log("getting price in main")
      let the_price = await get_price_with_web3(payload.nft_id)
      return the_price
    },
    async get_single_price(context, payload) {
      // console.log("CONTEXT", context)
      console.log("getting price in main")
      let the_price = await get_single_price_with_web3(payload.nft_id)
      return the_price
    },
    async get_left(context, payload) {
      // console.log("CONTEXT", context)
      console.log("getting left in main")
      let the_price = await get_left_with_web3(payload.nft_id)
      return the_price
    },
    async open_pack(context, payload) {
      // openAndBurn
      console.log("OPENING PACK at index: ", this.state.current_block_pack_index)
      return open_block_pack_with_web3(this.state.current_block_pack_index)

    },
    async redeem_block_card(context, payload) {
      console.log("REDEEM block card:", payload)
      return redeem_block_card_with_web3(payload.base_id, payload.card_id, payload.card_address)
    },

    
    async unstake_block_card(context, payload) {
      console.log("unstake_block_card:", payload)
      return unstake_block_card_with_web3(payload.base_id, payload.card_id, payload.card_address)
    },

    async add_chain(context, payload) {
      if (payload.chain === "mainnet") {
        // return await add_chain_bsc_testnet_with_web3()
        return await add_chain_bsc_mainnet_with_web3()
      }
      else {
        return await add_chain_bsc_testnet_with_web3()
      }
    },

    async get_total_block_card_supply(context, payload) {
      console.log("in get_total_block_card_supply at index: ", payload.index)
      let result = await get_total_block_card_supply_with_web3(payload.index)
      console.log("total owned", result)
      return result
    },

    async get_total_locker_supply(context, payload) {
      console.log("in get_total_locker_supply at index: ", payload.index)
      let result = await get_total_locker_supply_with_web3(payload.index)
      console.log("total owned", result)
      return result
    },
    
    async get_total_block_pack_supply(context, payload) {
      console.log("in get_total_block_pack_supply at index: ", payload.index)
      let result = await get_total_block_pack_supply_with_web3(payload.index)
      console.log("total owned", result)
      return result
    },
    async send_block_card(context,payload){
      let result = false
      try{
        console.log("send_block_card")
        console.log(payload)
        result = await send_block_card_with_web3(payload.to_address, payload.tokenId, payload.index)
      }
      catch(err){
        console.log(err)
        return result
      }
    },

    async list_block_card(context,payload){
      let result = false
      try{
        console.log("list_block_card")
        console.log(payload)
        let value_amount =  Web3.utils.toWei(String(payload.trade_value), 'ether');
        result = await list_block_card_with_web3(payload.tokenId, payload.index, value_amount, payload.trade_address)
      }
      catch(err){
        console.log(err)
        return result
      }
    },

    async stake_block_card(context,payload){
      let result = false
      try{
        console.log("stake_block_cardstake_block_card")
        console.log(payload)
        result = await stake_block_card_with_web3(payload.tokenId, payload.index)
      }
      catch(err){
        console.log(err)
        return result
      }
    },

    async trade_block_card(context,payload){
      let result = false
      try{
        console.log("trade_block_card")
        console.log(payload)
        let value_amount = Web3.utils.toWei(String(payload.trade_value), 'ether');
        result = await trade_block_card_with_web3(payload.tokenId, payload.index, value_amount, payload.trade_address)
      }
      catch(err){
        console.log(err)
        return result
      }
    },

    async trade_info(context,payload){
      let result = false
      try{
        console.log("list_block_card")
        console.log(payload)
        result = await trade_info_with_web3(payload.tokenId, payload.number)
      }
      catch(err){
        console.log(err)
        return result
      }
      return result
    },

    async is_approved_for(context,payload){
      let result = false
      try{
        console.log("is_approved_for")
        console.log(payload)
        result = await is_approved_for_with_web3(payload.index)
      }
      catch(err){
        console.log(err)
        return result
      }
      console.log("got approval?",result)
      return result
    },

    async is_approved_for_staking(context,payload){
      let result = false
      try{
        console.log("is_approved_for_stakikng")
        console.log(payload)
        result = await is_approved_for_staking_with_web3(payload.index)
      }
      catch(err){
        console.log(err)
        return result
      }
      console.log("got approval?",result)
      return result
    },

    async get_coin_balance(context,payload){
      
      console.log("in get_coin_balance")
      let result
      try{
        result = await get_coin_balance_with_web3(payload.address)
      }
      catch(err){
        console.log(err)
        return result
      }
      return result
    },

    async approve_nft(context,payload){
      let result = false
      let approve_type = "trader"
      if (payload.approve_type == "locker"){
        approve_type = "locker"
        console.log("approve type is locker")
      }
      try{
        console.log("is_approved_for")
        console.log(payload)
        result = await approve_nft_with_web3(payload.index,approve_type)
      }
      catch(err){
        console.log(err)
        return result
      }
      console.log("got approval?",result)
      return result
    },

    async send_block_pack(context, payload) {
      console.log("in send_block_pack at index: ", payload.index, payload.amount, payload.to_address)
      let promises = []
      let results_array
      for (let i = 0; i < payload.amount; i++) {
            promises.push(get_block_pack_index_with_web3(payload.index,i));
      }
      Promise.all(promises)
      .then((results) => {
          console.log("All done", results);
          
          let promises_of_trasfer = []
          for (let i = 0; i < results.length; i++) {
            promises_of_trasfer.push(transfer_block_pack_with_web3(payload.index,payload.to_address,results[i]));
          }
          Promise.all(promises_of_trasfer)
          .then((results) => {
              console.log("All Sent", results);
          })
          .catch((e) => {
              // Handle errors here
              console.log(e)
          });
      })
      .catch((e) => {
          // Handle errors here
          console.log(e)
      });

      
      // let result = await transfer_block_pack_with_web3(payload.index,payload.to_address)
      // console.log("total owned", result)
      // return result
    },

    

    

    async add_cards_to_array(context,payload){
      let string_of_supply = "current_block_card_supply" + String(payload.index)
      let string_of_indexes = "card_indexes" + String(payload.index)
      console.log(this.state[string_of_indexes])
      // return
      // if statement to make sure there are cards
        try{ 
          let web3_1 = new Web3(window.ethereum);
          let accounts = await web3_1.eth.getAccounts()
          console.log("adding cards")
          let string_of_cards = "current_block_cards" + String(payload.index)
          // console.log("YOOO",this.state.block_cards_updated[payload.index])
          // this.$store.state.block_cards_updated[this.block_card_index_number]
          let curr_supply = await get_total_block_card_supply_with_web3(payload.index)
          console.log(curr_supply, "hi")
          let new_cards_array = []
          let new_indexes = []
          for (let j = this.state.current_cards_per_pack; j > 0; j--){
            let nft_fetched = await fetch_block_card_with_web3(payload.index,curr_supply - j,accounts[0]) 
            // console.log(nft_fetched, "here")
            if (nft_fetched){
              nft_fetched.push(true)
            }
            new_indexes.unshift(nft_fetched[7])
            new_cards_array.unshift(nft_fetched)
          }
          console.log(new_cards_array, "here", payload.index)
          let new_array = new_cards_array.concat(this.state.block_cards_updated[payload.index])
          let new_index_array = new_indexes.concat(this.state[string_of_indexes])
          this.commit("change_state",{"name":string_of_indexes,"new_value":new_index_array})
          // tododododododododo
          // this.commit("change_state",{"name":"block_cards_updated","new_value":[...this.state.block_cards_updated ] })
          this.commit("update_block_cards",{"index": payload.index , "card_array":new_array})
          this.commit("change_state",{"name" : "block_cards_updated","new_value":[...this.state.block_cards_updated ] })
          this.commit("change_state",{"name" : string_of_supply, "new_value": (this.state[string_of_supply] + this.state.current_cards_per_pack )})
          // this.state.block_cards_updated = [...this.state.block_cards_updated ] 
          // need up update local storage here
          let stringy = JSON.stringify(new_array)
          localStorage[string_of_cards] = stringy

          localStorage[string_of_supply] = (this.state[string_of_supply])
          localStorage[string_of_indexes] = JSON.stringify(new_index_array)

            return true
        }
        catch(err){
            console.log(err)
            return false
        }
    },

    

    async after_stake_add_cards_to_array(context,payload){
      
      let string_of_supply = "current_block_card_supply_stake" + String(payload.index)
      let string_of_indexes = "card_indexes_stake" + String(payload.index)
      let num_cards = payload.num_cards
      console.log("num_cards",num_cards)
      console.log(this.state[string_of_indexes])
      // return
      // if statement to make sure there are cards
        try{ 
          let web3_1 = new Web3(window.ethereum);
          let accounts = await web3_1.eth.getAccounts()
          console.log("adding cards", payload.index)
          let string_of_cards = "current_block_cards_stake" + String(payload.index)
          // console.log("YOOO",this.state.block_cards_updated[payload.index])
          // this.$store.state.block_cards_updated[this.block_card_index_number]
          let curr_supply = await get_total_locker_supply_with_web3(payload.index)
          console.log(curr_supply, "hi")
          let new_cards_array = []
          let new_indexes = []
          for (let j = num_cards; j > 0; j--){
            console.log("inside")
            let locker_call = await fetch_locker_nft_with_web3(payload.index,curr_supply - j,accounts[0])
            
            let nft_fetched = await fetch_nft_for_staking(payload.index,locker_call,accounts[0]) 
           
            console.log(nft_fetched, "here19")
             // return 
             // todo cw
            if (nft_fetched){
              nft_fetched.push(true)
            }
            new_indexes.unshift(nft_fetched[7])
            new_cards_array.unshift(nft_fetched)
          }
          console.log(new_cards_array, "here", payload.index)
          let new_array = new_cards_array.concat(this.state.block_cards_stake_updated[payload.index])
          let new_index_array = new_indexes.concat(this.state[string_of_indexes])
          this.commit("change_state",{"name":string_of_indexes,"new_value":new_index_array})
          // tododododododododo
          // this.commit("change_state",{"name":"block_cards_updated","new_value":[...this.state.block_cards_updated ] })
          // block_cards_stake_updated
          this.commit("stake_update_block_cards",{"index": payload.index , "card_array":new_array})
          this.commit("change_state",{"name" : "block_cards_stake_updated","new_value":[...this.state.block_cards_stake_updated ] })
          this.commit("change_state",{"name" : string_of_supply, "new_value": (Number(this.state[string_of_supply]) + Number(num_cards ))})
          // this.state.block_cards_updated = [...this.state.block_cards_updated ] 
          console.log(this.state.string_of_supply,"what is this1?")
          // need up update local storage here
          let stringy = JSON.stringify(new_array)
          localStorage[string_of_cards] = stringy

          localStorage[string_of_supply] = (Number(this.state[string_of_supply]))
          localStorage[string_of_indexes] = JSON.stringify(new_index_array)

            return true
        }
        catch(err){
            console.log(err)
            return false
        }
    },

    async add_cards_to_array_after_single(context,payload){
      let string_of_supply = "current_block_card_supply" + String(payload.index)
      let string_of_indexes = "card_indexes" + String(payload.index)
      let num_cards = payload.num_cards
      console.log("num_cards",num_cards)
      console.log(this.state[string_of_indexes])
      // return
      // if statement to make sure there are cards
        try{ 
          let web3_1 = new Web3(window.ethereum);
          let accounts = await web3_1.eth.getAccounts()
          console.log("adding cards", payload.index)
          let string_of_cards = "current_block_cards" + String(payload.index)
          // console.log("YOOO",this.state.block_cards_updated[payload.index])
          // this.$store.state.block_cards_updated[this.block_card_index_number]
          let curr_supply = await get_total_block_card_supply_with_web3(payload.index)
          console.log(curr_supply, "hi")
          let new_cards_array = []
          let new_indexes = []
          for (let j = num_cards; j > 0; j--){
            console.log("inside")
            let nft_fetched = await fetch_block_card_with_web3(payload.index,curr_supply - j,accounts[0]) 
            console.log(nft_fetched, "here")
            if (nft_fetched){
              nft_fetched.push(true)
            }
            new_indexes.unshift(nft_fetched[7])
            new_cards_array.unshift(nft_fetched)
          }
          console.log(new_cards_array, "here", payload.index)
          let new_array = new_cards_array.concat(this.state.block_cards_updated[payload.index])
          let new_index_array = new_indexes.concat(this.state[string_of_indexes])
          this.commit("change_state",{"name":string_of_indexes,"new_value":new_index_array})
          // tododododododododo
          // this.commit("change_state",{"name":"block_cards_updated","new_value":[...this.state.block_cards_updated ] })
          this.commit("update_block_cards",{"index": payload.index , "card_array":new_array})
          this.commit("change_state",{"name" : "block_cards_updated","new_value":[...this.state.block_cards_updated ] })
          this.commit("change_state",{"name" : string_of_supply, "new_value": (Number(this.state[string_of_supply]) + Number(num_cards ))})
          // this.state.block_cards_updated = [...this.state.block_cards_updated ] 
          console.log(this.state.string_of_supply,"what is this1?")
          // need up update local storage here
          let stringy = JSON.stringify(new_array)
          localStorage[string_of_cards] = stringy

          localStorage[string_of_supply] = (Number(this.state[string_of_supply]))
          localStorage[string_of_indexes] = JSON.stringify(new_index_array)

            return true
        }
        catch(err){
            console.log(err)
            return false
        }
    },


    async fetch_specific_block_card(context,payload) {
      let web3_1
      let accounts
      let first_act
      try{
        web3_1 = new Web3(window.ethereum);
        accounts = await web3_1.eth.getAccounts()
        first_act = accounts[0]
      }
      catch{
        accounts = undefined
        first_act = undefined
      }
      
      let result = await fetch_specific_block_card_with_web3(payload.number, payload.tokenId,first_act)
      return result
    },

    async calculate_dust(context,payload) {
      let web3_1
      let accounts
      let first_act
      console.log("calculating dust")
      // // return 
      // try{
      //   web3_1 = new Web3(window.ethereum);
      //   accounts = await web3_1.eth.getAccounts()
      //   first_act = accounts[0]
      // }
      // catch{
      //   accounts = undefined
      //   first_act = undefined
      // }
      
      // let result = await fetch_specific_block_card_with_web3(payload.index, payload.tokenId,first_act)
      // console.log("dust result: ", result)

      // let data_object = result.card_info[6]
      // // data_array = data_array.slice(1,6)
      // let data_array = []
      // for (let i = 1; i < 7; i++ ){
      //   data_array.push(data_object[i])
      // }
      // console.log("data dust", data_array)

      let final_dust = await calculate_dust_with_web3(payload.index,payload.tokenId)

      return final_dust
    },

    async get_dust_staked(context,payload) {

      console.log("get_dust_staked")
      
      let final_dust = await get_dust_staked_with_web3(payload.address)

      return final_dust
    },

    async get_rewards_earned(context,payload) {

      console.log("get_rewards_earned")
      
      let rewards = await get_rewards_earned_with_web3(payload.address)

      return rewards
    },

    async redeem_rewards(context,payload) {

      console.log("redeem_rewards")
      
      let rewards = await redeem_rewards_with_web3(payload.address)

      return rewards
    },

    async dust_stake(context,payload) {

      console.log("dust_stake")
      
      let final_earned = await dust_stake_with_web3(payload.amount)

      return final_earned
    },

    async unstake_dust(context,payload) {

      console.log("unstake_dust")
      // return 
      let result = await unstake_dust_with_web3(payload.amount)

      return result
    },



    async add_token_to_wallet(context, payload) {
      let tokenAddress = payload.address
      let logo = payload.logo
      let tokenSymbol = payload.symbol

      // const tokenSymbol = 'TUT';
      let tokenDecimals = 18;
      if (tokenSymbol == "DOGE" ){
        tokenDecimals = 8;
      }
      // const tokenImage = 'http://placekitten.com/200/300';
      console.log(tokenAddress, tokenSymbol, logo, tokenDecimals)

      try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: tokenAddress, // The address that the token is at.
              symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: tokenDecimals, // The number of decimals in the token
              image: logo, // A string url of the token logo
            },
          },
        });

        if (wasAdded) {
          console.log('Thanks for your interest!');
        } else {
          console.log('Your loss!');
        }
      } catch (error) {
        console.log(error);
      }

    },
    async match_card_bounty(context, payload){
      console.log("matching card for bounty",payload)
      
      let web3_1
      let accounts
      let first_act
      try{
        web3_1 = new Web3(window.ethereum);
        accounts = await web3_1.eth.getAccounts()
        first_act = accounts[0]
      }
      catch{
        accounts = undefined
        first_act = undefined
      }
      
      let result = await match_card_bounty_with_web3(
      payload.bounty_address, payload.tokenId, payload.card_index, payload.block_card_type_address)
      return result
    },
    async redeem_bounty(context, payload){
      console.log("redeem_bounty",payload)
      let result = await redeem_bounty_with_web3(payload.address_of_cards
        , payload.bounty_address, 
        payload.token_ids)   

      return result 
      },
      async bounty_info(context, payload){
        console.log("bounty_info",payload)
        let result = await bounty_info_with_web3(payload.bounty_address,payload.bounty_info)   
  
        return result 
        }
      
      
  }







  // plugins:[test_plugin]
})

export const globalStore = new Vue({
  data: {
    globalvar: { account: "Connect Wallet" }
  }
})

/* eslint-disable no-new */
new Vue({
  router,
  i18n,
  data: { globlal1: "ICANSEEE" },
  store: store,
  render: h => h(App)
}).$mount("#app");
