<template>
  <div>
    <notifications></notifications>
    <router-view :key="$route.fullPath"></router-view>
    <modal :show.sync="modal_connect" class = "nft_modal" style="">
     <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Please Connect a Web3 Wallet like Metamask </h5>
        
    </template>
    <div class="" style="height: 40%">
        
    <div class="center"> 
      <p style="font-size: 20px">Please READ, you are almost there!
      </p>
      <p style="font-weight: bold" class="modal-title" id="exampleModalLabel">Make sure that you have your wallet configured and connected to BSC {{testnet_tag_on}} </p>
        <br>
        <div>
        <a v-if="true" target="blank" class="" style="margin: 10px"> 
          <base-button  style="padding-right: 30px" type="success" class="open_button" v-on:click="connect_wallet()">Connect Wallet</base-button>
        </a>
          
        <a href="https://metamask.io/" target="5" class="" style="margin: 10px"> 
          <base-button  style="padding-right: 30px" type="info" class="open_button">Get Metamask</base-button>
        </a>
        </div>
        <br>
     <p>Connecting allows You to interact with the Elemints app!</p>
     <br>
             <!-- <a v-if="testnet_tag_on == 'mainnet'"  class="" style="margin: 10px">  -->
               <a class="" style="margin: 10px"> 
          <base-button v-on:click="add_network()" style="padding-right: 30px; white-space: normal" type="success" 
          class="open_button">Add/Switch to {{testnet_tag_on}} BSC Network to Metamask </base-button>
        </a>
        <div style="" class="justify-content-center" >
          <!-- <div>
        <a v-if="true" target="blank" class="" style="margin: 10px"> 
          <base-button  style="padding-right: 30px" type="success" class="open_button" v-on:click="connect_wallet()">Connect Wallet</base-button>
        </a>
          
        <a href="https://metamask.io/" target="5" class="" style="margin: 10px"> 
          <base-button  style="padding-right: 30px" type="info" class="open_button">Get Metamask</base-button>
        </a>
        </div> -->
        
          <a href="https://docs.binance.org/smart-chain/wallet/metamask.html#connect-your-metamask-with-binance-smart-chain" target="6" class="" style="margin: 10px"> 
          <base-button  style="padding-right: 30px; white-space: normal" type="info" class="open_button">
          Learn How to Manually Connect to BSC {{testnet_tag_on}}</base-button>
        </a>
        
        <!-- <a v-if="testnet_tag_on == 'testnet'" href="https://testnet.binance.org/faucet-smart" target="7" class="" style="margin: 10px"> 
          <base-button  style="padding-right: 30px" type="primary" class="open_button">Get {{testnet_tag_on}} BNB </base-button>
        </a> -->
        <!-- <a v-if="testnet_tag_on == 'testnet'"  class="" style="margin: 10px"> 
          <base-button v-on:click="add_network('testnet')" style="padding-right: 30px" type="danger" class="open_button">Add {{testnet_tag_on}} BSC Network to Metamask </base-button>
        </a> -->

        <!-- <p> Any questions please join our <a href="t.me/elemints">Telegram</a> and ask for assistance </p> -->
     
         
        </div>
    </div>

</div>
     <template slot="footer">

     </template>
   </modal>
   
  </div>
</template>

<script>
  import Web3 from 'web3';
  const  { abi_elemint } = require("./abis.json");
  const mainnet_contracts = require("./contracts_data.json"); 
  const testnet_contracts = require("./test_contracts_data.json"); 
  const globals = require("./globals.json");
  import {globalStore} from './main.js';
    import Modal from '@/components/Modal';
  let elemint_token;

  if (globals.testnet){
    // using testnet
    console.log("using testnet");
    elemint_token = testnet_contracts.elemint_token
    let current_read_api;
  }
  else{
    // using mainnet
    console.log("using mainnet");
    elemint_token = mainnet_contracts.elemint_token
    let current_read_api;
  }
  

  export default {
    components:{
      Modal
    },
    computed:{
      modal_connect: {
        get: function () {
          return this.$store.state.modal_connect
        },
        set: function (new_value) {
          this.$store.state.modal_connect = new_value
        }

      },
      testnet_tag_on(){

        if (globals.testnet){
          return "testnet"
        }
        else{
          return "mainnet"
        }
      }
    },

    created: async function(){
      console.log("CREATED!");
      // console.log("BINANCE",window.BinanceChain);
      // let web3_2 = new Web3(window.BinanceChain);
      // let BSC_accounts = await web3_2.eth.getAccounts()
      // console.log("BSC defined?", BSC_accounts);
      try{
      let web3_1 = new Web3(window.ethereum);
      let accounts = await web3_1.eth.getAccounts()
      console.log("defined?", accounts);
      // let contract_token_1 = new web3_1.eth.Contract(abi_elemint,elemint_token.address)
      // "0xe553b8c3582077d756c7c936cf3d7d3cb646f25f"
      // for testing
      // contract_token_1.methods.balanceOf("0xe553b8c3582077d756c7c936cf3d7d3cb646f25f").call({from: accounts[0]})
      // let result = await contract_token_1.methods.balanceOf(accounts[0]).call({from: accounts[0]})
      // .then((result) => {console.log("total balance of ELMNT : ", result);
      //                   return result})
      //                   .then((token_balance) => {
      //                     this.$store.state.elemintTokenBalance = token_balance;
      //                   })
      }
      catch(err){console.log(err)
       console.log("ERROR")
      //  this.$store.state.modal_connect = true
       
       }

      setTimeout(() => {
        this.check_chain_status()
      }, 45000);
       
       

      
      try{
              window.ethereum.on('accountsChanged',() => {
              window.location.reload();

            // web3_1.eth.getAccounts().then((accounts) =>{
            // contract_token_1.methods.balanceOf(accounts[0]).call({from: accounts[0]})
            // .then((result) => {console.log("total balance of ELMNT : ", result);
            //                   return result})
            //                   .then((token_balance) => {
            //                     this.$store.state.elemintTokenBalance = token_balance;
            //                     this.$store.state.account = accounts[0];
            //                   })
            // })
            // location.reload(); 
            // todocw determine what behavior we want
            
              }
            )

            ethereum.on('chainChanged', (chainId) => {
          // Handle the new chain.
          // Correctly handling chain changes can be complicated.
          // We recommend reloading the page unless you have good reason not to.
            window.location.reload();
          });

            window.ethereum.on('connect',() => {
              console.log("CONNECT CONNECT")
              window.location.reload();

              }
            )

      }
      catch(err){

      }
    //   window.ethereum.on('accountsChanged',() => {
    //     window.location.reload();

    //   // web3_1.eth.getAccounts().then((accounts) =>{
    //   // contract_token_1.methods.balanceOf(accounts[0]).call({from: accounts[0]})
    //   // .then((result) => {console.log("total balance of ELMNT : ", result);
    //   //                   return result})
    //   //                   .then((token_balance) => {
    //   //                     this.$store.state.elemintTokenBalance = token_balance;
    //   //                     this.$store.state.account = accounts[0];
    //   //                   })
    //   // })
    //   // location.reload(); 
    //   // todocw determine what behavior we want
      
    //     }
    //   )

    //   ethereum.on('chainChanged', (chainId) => {
    // // Handle the new chain.
    // // Correctly handling chain changes can be complicated.
    // // We recommend reloading the page unless you have good reason not to.
    //   window.location.reload();
    // });

    //   window.ethereum.on('connect',() => {
    //     console.log("CONNECT CONNECT")
    //     window.location.reload();

    //     }
    //   )

      setTimeout(() => {
        console.log("wallet timeout", globalStore.globalvar.account)
        if(globalStore.globalvar.account === "Connect Wallet"){
          console.log("GETTING TO LAUNCH")
          this.$store.state.modal_connect = true; 
        }
      }, 45000);

      


    },
    methods: {
      disableRTL() {
        if (!this.$rtl.isRTL) {
          this.$rtl.disableRTL();
        }
      },
      toggleNavOpen() {
        let root = document.getElementsByTagName('html')[0];
        root.classList.toggle('nav-open');
      },
      connect_wallet(){
        console.log("CONNECT WALLET!!")
        this.$store.state.nav_bar_hook++ 
      },
      async check_chain_id(){
        console.log("hello5")
      let web3_1 = new Web3(window.ethereum)
      let netId = await web3_1.eth.net.getId()
      return netId
      },
      async check_chain_status(){
        try{
         let chain_id_now = await this.check_chain_id()
         if (globals.testnet){
           if (chain_id_now !== 97){
             console.log("IN HERE NOW",chain_id_now)
             this.$store.state.modal_connect = true 
             setTimeout(() => {
           this.check_chain_status()
         }, 60000);
           }
         }
         else{
           if (chain_id_now !== 56){
             console.log("IN HERE NOW2",chain_id_now)
             this.$store.state.modal_connect = true 
             setTimeout(() => {
           this.check_chain_status()
         }, 60000);
           }
         }
       }
       catch{
        //  console.log("IN HERE NOW3",chain_id_now)
         this.$store.state.modal_connect = true
         setTimeout(() => {
           this.check_chain_status()
         }, 60000);
       }
      },
      async add_network(){
        console.log()
        let chain_type
        if (globals.testnet){
            chain_type = 'testnet'
            console.log("testnet wow")
        }
        else{
          chain_type = 'mainnet'
          console.log("mainnet wow")
        }
        this.$store.dispatch("add_chain",{"chain":chain_type})

      }
    },
    mounted() {
      this.$watch('$route', this.disableRTL, { immediate: true });
      this.$watch('$sidebar.showSidebar', this.toggleNavOpen);
      // console.log("HELLO WORLD");
    }
  };
</script>

<style lang="scss"></style>
