<template>
  <div class="hello">
    <p style="color: #cb42f5; ">ELMNT Balance: {{balance}} </p>
  </div>
</template>

<script>
const globals = require("../globals.json");
const mainnet_contracts = require("../contracts_data.json"); 
const testnet_contracts = require("../test_contracts_data.json"); 

let contract_data; 

  if (globals.testnet){
    // using testnet
    // console.log("using testnet");
    contract_data = testnet_contracts
  }
  else{
    // using mainnet
    // console.log("using mainnet");
    contract_data = mainnet_contracts
  }

export default {
  name: 'TokenBalance',
  async mounted(){  
    "yo! mounted token balance"
    this.$store.state.elemintTokenBalance = 
    await this.$store.dispatch('get_coin_balance',{"address": contract_data.elemint })
  },
  computed: {
    balance(){
        let tokenB = BigInt(this.$store.state.elemintTokenBalance) / BigInt(10**18) ;
        return  tokenB;
    }
  }
}
</script>