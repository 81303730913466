<template>
 <div>


    <div v-if="true" ref="box" class="row the_box" >
    <div class="col-lg-6" v-for="(item, index) in data" :key="index" 
    style=" white-space: nowrap; margin-bottom: 100px">
      <!-- <slot :row="item" @click="activatemodal(item)"> -->
        <!-- <div v-for="(column, index) in columns"
            :key="index"
            @click="activatemodal(item)"
            >
          {{itemValue(item, column)}}
        </div> -->
      
        <!-- <div class ="nft_image" @click="activatemodal(item)">  -->
          <div class ="nft_image" > 
        <div> 
           <p class="center">{{item["nft"]}}</p>
        <!-- <img style="  display: block;
  margin-left: auto;
  margin-right: auto;
  
  " :src="the_image_1(index)" width="80%" @click="activatemodal(item)"> -->
          <img style="  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  
  " :src="the_image_1(index)" width="80%" @click="activatemodal(item)">
        <div v-if="done" class="top-right">
        <div  @click="activatemodal(item)" style="cursor: pointer" class="top-right2" v-if="item.left && Number(item.left[0]) < Number(item.left[1])"  > <i class="tim-icons icon-gift-2" 
        style="padding-right: 5px;
        padding-bottom: 5px;"></i><span style="font-weight: bold">{{item.left[1] - item.left[0]}} left</span></div>
         <!-- when it is sold out -->
         <div class="top-right2"  v-if="item.left && Number(item.left[0]) >= Number(item.left[1])" ><span style="font-weight: bold"></span> Sold Out </div>
         <!-- <div  class="top-right2"  v-if="!item.left" @click="activatemodal(item)" ></div> -->
         <div class="top-right3" style="cursor: pointer" @click="activatemodal(item)" v-if="!(item.left && Number(item.left[0]) >= Number(item.left[1]))" ><span style="font-weight: bold"></span> MINT PACK </div>
         <div class="top-right4"  style="cursor: pointer" @click="activatemodal(item,true)" v-if="!(item.left && Number(item.left[0]) >= Number(item.left[1])) && item.id == 3" ><span style="font-weight: bold"></span> MINT SINGLE </div>
        </div>
        </div>
                  
          

        </div>
        
        <!-- <td @click="activatemodal(item)" >{{left[item.id][0]}}/{{left[item.id][0]}} left &nbsp; mint now!</td> -->
        <!-- <td @click="activatemodal(item)" >{{left[item.id][0]}}/{{left[item.id][0]}} left &nbsp; mint now!</td> -->
         <!-- <div v-if="item.left && Number(item.left[0]) < Number(item.left[1])" @click="activatemodal(item)" ><span style="font-weight: bold">{{item.left[1] - item.left[0]}} left</span> &nbsp; &nbsp; &nbsp; mint now!</div> -->
         <!-- when it is sold out -->
         <!-- <div v-if="item.left && Number(item.left[0]) >= Number(item.left[1])" ><span style="font-weight: bold"></span> Sold Out </div> -->
         <!-- <div v-if="!item.left" @click="activatemodal(item)" > mint now!</div> -->

        <!-- add ammount left here ^^ -->
        <div class="center bottom-left">
        <p><img src="img/binance-coin-bnb-logo.png" style="width: 20px;
        margin: 5px; padding-bottom: 2px" />Pack &nbsp; {{item["price (bnb)"]}}</p>   
        <p v-if="item.single_price"><img src="img/binance-coin-bnb-logo.png" style="width: 20px;
        margin: 5px; padding-bottom: 2px" />Single &nbsp; {{item["single_price"]}}</p>   
        </div>
        
      <!-- </slot> -->
      
    <!-- </tr>
    </tbody> -->
    <!-- will need a v-for??? todocw -->
    <modal :show.sync="modals[item.id]" class = "nft_modal">
     <template slot="header">
        <h5 class="modal-title" id="exampleModalLabel">Mint: <span style="font-weight: bold">{{item.nft}} {{pack_or_single}}</span> </h5>
     </template>
  
    <div class="" style="height: 40%">
<div class="center"> 
    <div id="image" style="display:inline;">
             
        <img v-if="pack_or_single == 'packs'" :src="the_image_1(index)" style="display:inline; width: 21rem" />
        <img v-if="pack_or_single != 'packs'" src="img/blurr3.png" style="display:inline; width: 21rem" />
    </div>
        
    </div>
    
      <div class="row justify-content-center" >
        <p>Mint {{item.nft}} for {{price_on_modal}} BNB</p>
      </div>
      <div class="row">
      <div class="col-md-6" v-if="multiple_minter(index)">
        How many {{pack_or_single}} to mint:
        <input class="color_over  form-control" v-model="number_nfts_current"/>
        Address to send to:
        <input class="color_over  form-control" v-model="address_current"/>
      </div>
        
      <div class=" col-md-6">
        <mint-button  class="center" v-if="!mint_spin" style="margin-top: 50px" size="lg" :nft_id="item.id" :item_id="item" :clicked_nft="modal_info" @mint="mint_nft"></mint-button>
         <div style="" v-if="mint_spin">
           <div class="self-building-square-spinner" style="margin-top: 50px; margin-left: 50px">
              <div class="square"></div>
              <div class="square"></div>
              <div class="square"></div>
              <div class="square clear"></div>
              <div class="square"></div>
              <div class="square"></div>
              <div class="square clear"></div>
              <div class="square"></div>
              <div class="square"></div>
            </div>
         </div>
       
    </div>
      </div>


</div>
     <template slot="footer" >
       
     </template>
   </modal>
     </div>
    </div>
 
            
  </div>
</template>

<script>

import Modal from '@/components/Modal';
import MintButton from '@/components/MintButton'
import ApproveButton from '@/components/ApproveButton'
const mainnet_contracts = require("../contracts_data.json"); 
const testnet_contracts = require("../test_contracts_data.json"); 
const globals = require("../globals.json");
import {globalStore} from '../main.js'
// const abis = require("../abis.json");

let contract_data;

if (globals.testnet){
  // using testnet
  // console.log("using testnet");
  contract_data = testnet_contracts
}
else{
  // using mainnet
  // console.log("using mainnet");
  contract_data = mainnet_contracts
}
function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


  export default {
    created: async function(){

            setTimeout(function () {
                this.done = true;
            }.bind(this), 500);
    },
    components:{
      Modal,
      MintButton,
      ApproveButton},
    name: 'base-nft-table',
    props: {
      columns: {
        type: Array,
        default: () => [],
        description: "Table columns"
      },
      data: {
        type: Array,
        default: () => [],
        description: "Table data"
      },
      type: {
        type: String, // striped | hover
        default: "",
        description: "Whether table is striped or hover type"
      },
      theadClasses: {
        type: String,
        default: '',
        description: "<thead> css classes"
      },
      tbodyClasses: {
        type: String,
        default: '',
        description: "<tbody> css classes"
      },
      item_id: {
        type: Number,
        default: 0,
        description: "id number"
      }
    },
    computed: {
        address_current: {
        get: function () {
          if (this.nft_model.address =="" ){
            return globalStore.globalvar.account
          }
          else{
            return this.nft_model.address
          }
        },
        set: function (new_value) {
          this.nft_model.address = new_value
          this.$store.state.block_pack_minting_address = new_value
        }

      },
        number_nfts_current: {
        get: function () {
            return this.nft_model.number
        },
        set: function (new_value) {
          this.nft_model.number = new_value
          this.$store.state.block_pack_minting_number = new_value
        }

      },
      // address_current(){
      //   return globalStore.globalvar.account
      // },
      mint_spin(){
        console.log("mint spin?")
        return this.$store.state.mint_spin
      },
      tableClass() {
        return this.type && `table-${this.type}`;
      },
      // the_image(){
      //     return "img/silent_investor_small.png"
      // },
      clicked_nft(){
          return this.modal_info
      },
      contract_data (){
        return contract_data
      },
      coin_approved(){
        return this.$store.state.approved[0][0]
      }
    },
    methods: {
      async get_left(id){
        try{
        let the_price = await this.$store.dispatch('get_left',{"nft_id":id})
        this.left[id] = the_price ;
        console.log("left got",this.left[id])
        
        }
        catch(err){
          
          this.left[id] = false ;
        }
        this.got_left = true
        
      },

      hasValue(item, column) {
        return item[column.toLowerCase()] !== "undefined";
      },
      itemValue(item, column) {
        return item[column.toLowerCase()];
      },
      async activatemodal(item, single=false){
                /////// doesnt work
        if (single){
          this.pack_or_single = "single card"
        }
        else{
          this.pack_or_single = "packs"
        }

        if(globalStore.globalvar.account === "Connect Wallet"){
          console.log("GETTING TO LAUNCH")
          this.$store.state.modal_connect = true; 
          return false
        }
        // determine if sold out or not
        let question = item.left && Number(item.left[0]) >= Number(item.left[1])
        console.log("question: ",question)
        if (question){
          return
        }
        /////
        this.address_current = globalStore.globalvar.account
        this.number_nfts_current = 1

        console.log(item, "WAT IS IT?")
          this.modals.modal0 = true;
          this.modals[item.id] = true;
          this.modal_info = item;
          if (single == true){
            this.modal_info["single"] = true
            this.price_on_modal = ((Number(await (this.$store.dispatch('get_single_price',{"nft_id":item.id}))) + 1e15) / 1e18 ).toFixed(4)
            
          }
          else{
            this.modal_info["single"] = false
            this.price_on_modal = ((Number(await (this.$store.dispatch('get_price',{"nft_id":item.id}))) + 1e15) / 1e18 ).toFixed(4)
           }
          console.log(this.modal_info, "WAT IS IT 2?")
          // this.price_on_modal = ((Number(await (this.$store.dispatch('get_price',{"nft_id":item.id}))) + 1e15) / 1e18 ).toFixed(4)
          
          // let result = await this.$store.dispatch('check_approved',{"token_data": item.id, "coin_id":0})
          // console.log("result ISS: ", result)
          // this.test_aproved = false
      },
      mint_nft(){
        console.log("MINTING")
        this.$emit("mint",this.modal_info)
      },      
      async approved(token_data,coin_id){
        // return true
        console.log("checking approval: ",token_data)
        // console.log("WOOYOOO",this.$store.state.approved[token_id])
        // console.log(this.$store.dispatch('check_approved',{"token_data": token_data, "coin_id":coin_id}))
        // return this.$store.state.approved[token_data.id][coin_id]
        return true
      },
      approve_coin(id){
        console.log("approving coin!: ", id)
        this.$emit("approve_coin",id)
      },
      // the_image_1(thing){
      //   console.log("whatsup form image", thing.id)
      //   return contract_data.block_packs[thing.id-1].image
      // },
      the_image_1(thing){
        // console.log("whatsup form image", thing)
        return contract_data.block_packs[thing].image
      },
      multiple_minter(thing){
        console.log("multiple_minter?", contract_data.block_packs[thing].mint_multiple)
        return contract_data.block_packs[thing].mint_multiple
      }
    },
    data() {
        return {
        nft_model:{
            selected: "",
            selectedIndex: -1,
            number: 1,
            address: ""
        },
        pack_or_single: "",
        done: false,
        modalVisible: false,
        modals: {modal0:false, 
                0:false,
                1: false ,
                2: false,
                3: false,
                4: false,
                5: false},
        modal_info: {},
        current_coin_id : 0,
        current_nft_id : 0,
        test_aproved: false,
        price_on_modal: "",
        left:{1:[0,0],
        2:[0,0]},
        got_left: false,
        
        }
    },

    
  };
</script>
<style>
.nft_image{
    size: 10em;
}
.the_container{
  white-space:nowrap;
}
.nft_image_inner{
    /* size: 1rem; */
    display: inline;
}
.nft_modal{
}
.center {
  margin: auto;
  width: 80%;
  padding: 10px;
  /* white-space: nowrap; */
  text-align: center;
}


/*  */
.self-building-square-spinner, .self-building-square-spinner * {
      box-sizing: border-box;
    }

    .self-building-square-spinner {
      height: 40px;
      width: 40px;
      top: calc( -10px * 2 / 3);
    }

    .self-building-square-spinner .square {
      height: 10px;
      width: 10px;
      top: calc( -10px * 2 / 3);
      margin-right: calc(10px / 3);
      margin-top: calc(10px / 3);
      background: #2e9e54;
      float: left;
      position:relative;
      opacity: 0;
      animation: self-building-square-spinner 4s infinite;
    }

    .self-building-square-spinner .square:nth-child(1) {
      animation-delay: calc(200ms * 6);
    }

    .self-building-square-spinner .square:nth-child(2) {
      animation-delay: calc(200ms * 7);
    }

    .self-building-square-spinner .square:nth-child(3) {
      animation-delay: calc(200ms * 8);
    }

    .self-building-square-spinner .square:nth-child(4) {
      animation-delay: calc(200ms * 3);
    }

    .self-building-square-spinner .square:nth-child(5) {
      animation-delay: calc(200ms * 4);
    }

    .self-building-square-spinner .square:nth-child(6) {
      animation-delay: calc(200ms * 5);
    }

    .self-building-square-spinner .square:nth-child(7) {
      animation-delay: calc(200ms * 0);
    }

    .self-building-square-spinner .square:nth-child(8) {
      animation-delay: calc(200ms * 1);
    }

    .self-building-square-spinner .square:nth-child(9) {
      animation-delay: calc(200ms * 2);
    }

    .self-building-square-spinner .clear{
      clear: both;
    }

    @keyframes self-building-square-spinner {
      0% {
        opacity: 0;
      }
      5% {
        opacity: 1;
        top: 0;
      }
      50.9% {
        opacity: 1;
        top: 0;
      }
      55.9% {
        opacity: 0;
        top: inherit;
      }
    }

    .color_over{
      color : black !important;
    }

  .top-right {
  position: absolute;
  top: 65px;
  right: 65px;
  /* background: linear-gradient(0deg, #389466 0%, #42b883 100%); */
  /* border-radius: 10%; */
  /* border-color: black; */
  /* border-style: solid; */
  /* padding: 3%; */
  width: 25%;
  margin-right:20px;
  color: white;
  text-align: center;
}
  .top-right2 {
  position: relative;
  /* top: 125px;
  right: 65px; */
  background: linear-gradient(0deg, rgba(53, 219, 144, 0.8) 0%,  rgb(46, 105, 76,0.8)  150%);
  border-radius: 10%;
  width: 100%;
  /* border-color: black; */
  /* border-style: solid ; */
  border-width: 1px;
  padding: 10%;
  margin:10px;
  color: white;
  text-align: center;

}
  .top-right3 {
  position: relative;
  /* top: 125px;
  right: 65px; */
  background: linear-gradient(0deg, rgba(255, 145, 0, 0.95) 0%, rgb(255, 251, 0,0.95) 150%);
  border-radius: 10%;
  width: 100%;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  padding: 10%;
  margin:10px;
  color: white;
  text-align: center;

}

.top-right4 {
  position: relative;
  /* top: 125px;
  right: 65px; */
  background: linear-gradient(0deg, rgb(255, 130, 130, 0.95) 0%, rgb(255, 0, 0, 0.95) 100%);
  border-radius: 10%;
  width: 100%;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  padding: 10%;
  margin:10px;
  color: white;
  text-align: center;

}

.bottom-left{
  position: absolute;
  bottom: -40px;
  left: 25px;
  /* background: linear-gradient(0deg, #389466 0%, #42b883 100%); */
  /* border-radius: 10%; */
  /* border-color: black; */
  /* border-style: solid; */
  /* padding: 3%; */
  width: 25%;
  
  color: white;
  text-align: center;

}
/*  */
</style>