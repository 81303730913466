<template>
<!-- TODO CW -->
  <nav class="navbar navbar-expand-lg navbar-absolute"
       :class="{'bg-white': showMenu, 'navbar-transparent': !showMenu, 'bg-dark': isDark&&showMenu}">
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div class="navbar-toggle d-inline" :class="{toggled: $sidebar.showSidebar}">
          <button type="button"
                  class="navbar-toggler"
                  aria-label="Navbar toggle button"
                  @click="toggleSidebar">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <a  class="navbar-brand" :class="{'colorwhite': isDark}" href="#">{{routeName}}</a>
      </div>
      <button class="navbar-toggler" type="button"
              @click="toggleMenu"
              data-toggle="collapse"
              data-target="#navigation"
              aria-controls="navigation-index"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>
    

      <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu">
          <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
            
            <!-- todo fix style cw -->
            <div style="margin-right: 15px; margin-left: 122px">
              <token-balance style="margin: 10px; padding-top: 7px" class = "token"/>
            </div>
            <div v-if="nav_hook"></div> 
            <!-- div for only hooking ^^ -->
            <!-- <modal :show.sync="searchModalVisible"
                   class="modal-search"
                   id="searchModal"
                   :centered="false"
                   :show-close="true">
              <input slot="header" v-model="searchQuery" type="text" class="form-control" id="inlineFormInputGroup" placeholder="SEARCH">
            </modal> -->
            <!-- <base-radio :inline="true" @click="toggleDark">
            darkmode
            </base-radio> -->
            <!-- <base-button class="btn-link btn-neutral" slot="header" type="primary" @click="InitConnect">{{wallet_name}}</base-button> -->
            <a v-if="testnet_tag_on == 'testnet'" href="https://testnet.binance.org/faucet-smart" target="7" class="" style="margin: 10px"> 
          <base-button  style="padding-right: 30px"  class="btn-link btn-neutral" >Get {{testnet_tag_on}} BNB! </base-button>
          </a>
                      <!-- <a v-if="testnet_tag_on == 'testnet'" href="https://coinmarketcap.com/currencies/binance-coin/markets/" target="7" class="" style="margin: 10px"> 
          <base-button  style="padding-right: 30px"  class="btn-link btn-neutral" >Get BNB! </base-button>
          </a> -->
            <base-button v-cloak v-if="!wallet_connected && done_loading" class="btn-link btn-neutral" slot="header" >{{connection}}</base-button>
            <base-button v-cloak v-if="wallet_connected && done_loading" type="warning"  style="color: black" class="" slot="header" @click="InitConnect">{{connection}}</base-button>
            <!-- <base-button class="btn-link btn-neutral" slot="header" type="primary" @click="connectAccountWrappper">{{connection}}</base-button> -->
          
            <div style="padding-top: 14px">
            {{DarkMode}}
            <toggle-button :value="isDark" @change="toggleDark"/>
            </div>
            
       
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
  import { CollapseTransition } from 'vue2-transitions';
  import Modal from '@/components/Modal';
  import TokenBalance from '@/components/TokenBalance';
  import CloseButton from '@/components/CloseButton';
  import NavbarToggleButton from '@/components/NavbarToggleButton';
  import Web3 from 'web3';
  import {globalStore} from '../../main.js'
  const globals = require("../../globals.json");
  let currentAccount = null;



  
  

  async function wrapper(innerFunction){
     return await innerFunction();
    // do whatever you need with vm.feed below
 }

// function handleAccountsChanged(accounts) {
//   if (accounts.length === 0) {
//     // MetaMask is locked or the user has not connected any accounts
//     console.log('Please connect to MetaMask.');
//   } else if (accounts[0] !== currentAccount) {
//     currentAccount = accounts[0];
//     // Do any other work!
//     console.log(currentAccount);
//   }
// }

//   const ethEnabled = async () => {
//   if (window.ethereum) {
//     await window.ethereum.send('eth_requestAccounts');
//     window.web3 = new Web3(window.ethereum);
//     return true;
//   }
//   return false;
// }

const connectAccount = async (clicked) => {

 if(typeof window.ethereum!=='undefined'){
      const web3_1 = new Web3(window.ethereum)
      const netId = await web3_1.eth.net.getId()
      const accounts = await web3_1.eth.getAccounts()

      //load balance
      if(typeof accounts[0] !=='undefined'){
        const balance = await web3_1.eth.getBalance(accounts[0])
        console.log(accounts,netId,balance);
        // this.setState({account: accounts[0], balance: balance, web3: web3})
        //web3: web3
        return {account: accounts[0], balance: balance};
      } else {
        // window.alert('Please login with MetaMask')
        console.log("wanted a metamask")
      }

      //load contracts
      try {
        // const token = new web3.eth.Contract(Token.abi, Token.networks[netId].address)
        // const dbank = new web3.eth.Contract(dBank.abi, dBank.networks[netId].address)
        // const dBankAddress = dBank.networks[netId].address
        // this.setState({token: token, dbank: dbank, dBankAddress: dBankAddress})
      } catch (e) {
        console.log('Error', e)
        window.alert('Contracts not deployed to the current network')
      }

    } else {
      // window.alert('Please install MetaMask')
    }
  
}

  export default {
    components: {
      CollapseTransition,
      Modal,
      NavbarToggleButton,
      TokenBalance,
    },
    created: function(){
      const wrapper_2 = () => {
      this.connectWallet();
      this.checkWallet();
      
      }
      setTimeout(() => {
        this.done_loading = true;
      }, 1000);
      
      const connect_wrapper = () => {
        connectAccount().then( (result,) => {
          console.log("THE RESULT", result);
          if(this.wallet_name != result.account){
            console.log("getting here")
            this.wallet_name = result.account;
            globalStore.globalvar.account = result.account;
            this.$store.state.wallet_address = result.account;
          }
          
        } ).catch((error) => {console.log("no wallet connected")});
      }

      // wrapper_2();
      const change_wallet_address = (new_address) =>{
        this.wallet_name = new_address
        globalStore.globalvar.account = new_address;
        this.$store.state.wallet_address = new_address;
      }


      if (this.first_load === true){
          connect_wrapper();
          this.first_load = false;
      }
      
      
      try{
            window.ethereum.on('accountsChanged', function (accounts) {
            // Time to reload your interface with accounts[0]!
            console.log("something changed")
            let address = accounts[0];
            if (address === undefined){
              address = "Connect Wallet";
            }
            change_wallet_address(address);
            // contract_token_1.methods.balanceOf(accounts[0]).call({from: accounts[0]}).
            // then((token_balance) =>{
            //   his.$store.state.elemintTokenBalance = token_balance;
            // })
            // this.$store.state.elemintTokenBalance = elemint_wallet_balance;
            // this.$store.state.elemintTokenBalance = token_balance;
            // wrapper_2();
          })
      }
      catch(err){

      }
    //   window.ethereum.on('accountsChanged', function (accounts) {
    //   // Time to reload your interface with accounts[0]!
    //   console.log("something changed")
    //   let address = accounts[0];
    //   if (address === undefined){
    //     address = "Connect Wallet";
    //   }
    //   change_wallet_address(address);
    //   // contract_token_1.methods.balanceOf(accounts[0]).call({from: accounts[0]}).
    //   // then((token_balance) =>{
    //   //   his.$store.state.elemintTokenBalance = token_balance;
    //   // })
    //   // this.$store.state.elemintTokenBalance = elemint_wallet_balance;
    //   // this.$store.state.elemintTokenBalance = token_balance;
    //   // wrapper_2();
    // })

        if ("white-content" == localStorage.getItem("theme")){
          this.DarkMode = "DarkMode"
          // this.isDark = !this.isDark
        }
        else{
          this.DarkMode = "LightMode"
          this.isDark = !this.isDark
        }
        console.log(this.DarkMode)
        console.log(this.isDark)
    },
    computed: {
      routeName() {
        const { name } = this.$route;
        return this.capitalizeFirstLetter(name);
      },
      isRTL() {
        return this.$rtl.isRTL;
      },
      connection(){
        // return globalStore.globalvar.account.toUpperCase();
        return globalStore.globalvar.account;
      },
      nav_hook(){
        // this.$store.state.nav_bar_hook;
        console.log("hooked into navbar", this.$store.state.nav_bar_hook)
        if (this.$store.state.nav_bar_hook != 0){
          this.InitConnect()
        }
        return this.$store.state.nav_bar_hook 
      },
      wallet_connected(){
        if(globalStore.globalvar.account === "Connect Wallet"){
          return true
        }
        else{
          return false
        }
        // if(this.$store.state.wallet_connected){
        //   return true
        // }
        // else{
        //   return false
        // }

      },
      testnet_tag_on(){

        if (globals.testnet){
          return "testnet"
        }
        else{
          return ""
        }
      }
      // DarkMode() {
      //   if ("white-content" == localStorage.getItem("theme")){
      //     return "DarkMode"
      //   }
      //   else{
      //     return "LightMode"
      //   }
      // }
    },
    data() {
      return {
        first_load: true,
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: '',
        isDark: false,
        DarkMode: "DarkMode",
        wallet_name: "Connect Wallet",
        done_loading: false
      };
    },
    watch:{
      
    },
    methods: {
      // wallet methods
      handleAccountsChanged(accounts) {
        if (accounts.length === 0) {
          // MetaMask is locked or the user has not connected any accounts
          console.log('Please connect to MetaMask.');
        } else if (accounts[0] !== currentAccount) {
          currentAccount = accounts[0];
          // Do any other work!
          console.log(currentAccount, "here11");
          this.wallet_name = currentAccount;
          // globalStore.globalvar.account = currentAccount;
        }
      },
      InitConnect() {
        try{
                  window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(this.handleAccountsChanged)
        .catch((err) => {
          if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
            console.log('Please connect to MetaMask.');
          } else {
            console.error(err);
          }
        }).finally(() => {this.connect_wrapper_method()});

        }
        catch(err){
          this.$store.state.modal_connect = true;
        }
        // ethereum
        // .request({ method: 'eth_requestAccounts' })
        // .then(this.handleAccountsChanged)
        // .catch((err) => {
        //   if (err.code === 4001) {
        //     // EIP-1193 userRejectedRequest error
        //     // If this happens, the user rejected the connection request.
        //     console.log('Please connect to MetaMask.');
        //   } else {
        //     console.error(err);
        //   }
        // }).finally(() => {this.connect_wrapper_method()});
      },
      //
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      toggleDark() {
        if (this.isDark){
          document.getElementById("the_body").classList.add('white-content');
          this.DarkMode = "DarkMode"
          localStorage.setItem("theme", "white-content");
          // console.log("got here1")
        }
        else{
          document.getElementById("the_body").classList.remove('white-content');
          this.DarkMode = "LightMode"
          localStorage.setItem("theme", "");
          // console.log("got here2")
        }
        this.isDark = !this.isDark;

        // console.log("got here3")
        
      },
      connectWallet(){
        console.log('hello world 2');
        // ethEnabled(); // one version
        connect();
        
      },
      checkWallet(){
        // window.web3 = new Web3(window.ethereum);
        
        // globalStore.globalvar = connectAccount();
        
        connectAccount()
        .then(function(result){
          console.log(result,"yess 88");
          globalStore.globalvar = result;
        })
        .catch(error => {
          console.log("Error Handled");
        })
        .finally(() => {
          console.log("yes sir")
        });
        // connectAccount();
        // connectAccount.then(function(result) {
        // console.log(result) // "Some User token"
        // })
        // console.log("hi",globalStore.globalvar);
        
      },
      checkState(){
        console.log("hi",globalStore.globalvar.account);
      },
      checkAddress(){
        return globalStore.globalvar.account != undefined;
      },
      connect_wrapper_method() {
        connectAccount().then( (result,) => {
          console.log("THE RESULT2", result);
          if(this.wallet_name != result.account){
            console.log("getting here")
            this.wallet_name = result.account;
            globalStore.globalvar.account = result.account;
            this.$store.state.wallet_address = result.account;
          }
          
        } ).catch((error) => {console.log("no wallet connected")});
      }

    }
  };
</script>
<style>
.token{
  margin-top : 9px;
}
.colorwhite{
  color:white;
}
[v-cloak] {
  display: none;
}
</style>
