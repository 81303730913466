<template>
<div>
    <base-button @click="pressed" > 
    Approve {{coin}}
    </base-button>
</div>
</template>

<script>

import BaseButton from './BaseButton.vue'

export default {
    name:"approve-button",
    components:{
        BaseButton
    },
    props:{
    coin_id: {
        type: Number,
        default: () => 0,
        description: "id of coin"
      },
      coin:{
        type: String,
        default: () => "BUSD",
        description: "name of coin to approve"

      },
      address_to_approve: {
        type: String,
        default: () => "",
        description: "address to approve"

      }
    },
    methods: {
        pressed(){
            console.log("Approval button clicked" );
            this.$emit('approve',this.coin_id)
            this.$store.state.test_function()
            // console.log("HHHHHHHHEEERE!!",this.address_to_approve)
            this.$store.dispatch('approve_erc20',{"coin_id":this.coin_id,
            "address_to_approve": this.address_to_approve})
        }
    }
    
}
</script>