<template>
<div>
    <base-button @click="pressed" type="info" > 
    Mint Now
    </base-button>
</div>
</template>

<script>

import BaseButton from './BaseButton.vue'
import {globalStore} from '../main.js' 

export default {
    name:"mint-button",
    components:{
        BaseButton
    },
    props:{
    nft_id: {
        type: Number,
        default: () => -1,
        description: "determines what nft to mint"
      },
    clicked_nft: {
        type: Object,
        default: () => {},
        description: "determines what nft to mint"
      },
    number_to_mint:{
        type: Number,
        default: () => 1,
        description: "determines number to mint"
    }
    },
    methods: {
        async pressed(){
  
            console.log("current addres to send to still: ",this.$store.state.block_pack_minting_address)
            console.log("current number of nfts to mint", this.$store.state.block_pack_minting_number)
            console.log("NFT minter clicked", JSON.stringify(this.clicked_nft));
            console.log("NFT minter clicked 2", this.clicked_nft);
            this.$emit('mint')
            console.log("MINTING ID: ", this.$props.nft_id)
            // this.$store.dispatch('mint_nft',{"nft_info":this.clicked_nft})
            this.$store.state.mint_spin = true;
            try{
                if (this.clicked_nft.single){ // is single
                let result = await this.$store.dispatch('mint_single_nft',{"nft_info":this.clicked_nft,
                 "address": this.$store.state.block_pack_minting_address,
                 "number": this.$store.state.block_pack_minting_number,
                 })
                let add_cards_result = await this.$store.dispatch("add_cards_to_array_after_single",
                {"index":this.clicked_nft.inverse_index,
                "num_cards": this.$store.state.block_pack_minting_number
                }
                )

                }
                else{ // regular pack mint
                let result = await this.$store.dispatch('mint_nft',{"nft_info":this.clicked_nft,
                 "address": this.$store.state.block_pack_minting_address,
                 "number": this.$store.state.block_pack_minting_number,
                 })

                }
                 
                 this.$store.state.block_pack_minting_address = undefined
                 this.$store.state.block_pack_minting_number = undefined
                 this.$store.state.mint_spin = false;
                //  this.$store.state.block_packs = []
                // this.$store.state.block_cards = []
                if (this.$route.name != "nfts"){
                    this.$router.push("nfts")
                }
                 this.$store.state.reload_block_packs = true
                //  this.$router.go()
                // window.location("/nfts")
            }
           catch(err){
               
               console.log(err)
            //    this.$store.state.block_packs = []
            //    this.$store.state.block_cards = []
                // this.$router.push("nfts")
                //  this.$router.go()
            //    window.location("/nfts")

           }
            console.log("got a result")
            this.$store.state.mint_spin = false;

        }
    }
    
}
</script>